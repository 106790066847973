import {useEffect, useRef} from 'react';
import {Rect, Transformer} from 'react-konva';
import React from 'react';

export const ANCHOR_SIZE = 28;

export const SelectionRectangle = ({
  isSelected,
  onSelect,
  onChange,
  onDragMove,
  onTransform,
  position,
  dragBoundFn,
  transformBoundFn,
  transformerProps,
  ...rest
}) => {
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.setNode(shapeRef.current);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Rect
        onClick={onSelect}
        ref={shapeRef}
        fill="transparent"
        id="selectionRect"
        strokeDashArray={[10, 10]}
        borderStrokeWidth={4}
        strok="transparent"
        {...position}
        {...rest}
        draggable
        onDragMove={onDragMove}
        onTransform={onTransform}
        dragBoundFunc={dragBoundFn}
      />
      {!!isSelected && (
        <Transformer
          boundBoxFunc={transformBoundFn}
          ref={trRef}
          rotateEnabled={false}
          borderStrokeWidth={1}
          keepRatio={false}
          borderStroke="white"
          anchorFill="transparent"
          anchorStroke="white"
          anchorSize={ANCHOR_SIZE}
          {...transformerProps}
        />
      )}
    </>
  );
};
