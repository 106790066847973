import React from 'react';

export const Like = ({onToggleLike, likesCount, liked}) => {
  return (
    <button className={liked ? `button--unlike` : `button--like`} onClick={onToggleLike}>
      <i />
      {likesCount} like{likesCount !== 1 && 's'}
    </button>
  );
};

export default Like;
