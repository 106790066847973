import React from 'react';
import {Link} from 'react-router5';
import LoadingIndicator from '../../components/LoadingIndicator';

export const ThankYou = ({onRestart, imageUploading}) => (
  <div className="editor">
    <div className="h-page-center">
      {imageUploading ? (
        <h1 className="h-center editor-is-saving">
          <LoadingIndicator />
          Uploading, please wait...
        </h1>
      ) : (
        <h1 className="h-center">Thank you!</h1>
      )}
      <p className="h-center h-mt-10 h-mb-40">
        {imageUploading ? 'Your photo will be shared within the gallery in a moment.' : 'Your photo has been shared in the gallery.'}
      </p>
      <Link className="button button--primary h-mt-20" routeName="gallery">
        Open gallery
      </Link>
      <button type="button" className="button--secondary button--restart h-mt-20" onClick={onRestart}>
        <i />
        Create new photo
      </button>
    </div>
  </div>
);
