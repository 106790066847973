import {useEffect, useState, useCallback} from 'react';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

const options = {
  // This prevents recalculating the gallery grid layout size when lock is released and scroll bar is shown
  reserveScrollBarGap: true,
};

export const useLockBodyScroll = ({disableScroll}) => {
  // Body scroll is disabled, but scrolling of this node is permitted
  const [persistScrollNode, setPersistScrollNode] = useState(null);

  const persistScrollNodeRef = useCallback(node => {
    setPersistScrollNode(node);
  }, []);

  useEffect(() => {
    if (persistScrollNode === null) {
      return;
    }

    if (disableScroll) {
      disableBodyScroll(persistScrollNode, options);
    } else {
      enableBodyScroll(persistScrollNode);
    }
  }, [disableScroll, persistScrollNode]);

  return persistScrollNodeRef;
};
