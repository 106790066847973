import {takeEvery, call, put, select, delay, race, take} from 'redux-saga/effects';
import {actionTypes as routerActionTypes} from 'redux-router5';

import {gallery} from '../actions';
import {user} from '../actions';
import {API} from './constants';
import {getBearerToken} from '../selectors/auth';
import {getHasMore, getIsLoading, getOffset, getTotalFetched, getSelectedLabels, shouldSortImagesByLikes} from '../selectors/gallery';
import {getImagesWithPendingLikeStatusChange, getLikedImageIds} from '../selectors/user';

const IMAGES_LIMIT = 60;
const FIRST_CHECK_DELAY = 10 * 1000;
const IMAGE_LIKES_FETCH_DELAY = 30 * 1000;
const CHECK_DELAY = 60 * 1000;

function* getImages({limit: originalLimit, offset, sortByLikesOffset = 0, labels = []}) {
  const token = yield select(getBearerToken);
  const sortByLikes = yield select(shouldSortImagesByLikes);

  const response = yield call(
    fetch,
    `${API.GET_IMAGES}?offset=${offset || new Date().toISOString()}&limit=${originalLimit || IMAGES_LIMIT}&labels=${labels.join(
      ',',
    )}&sortByLikes=${sortByLikes}&sortByLikesOffset=${sortByLikesOffset}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      encoding: null,
    },
  );
  const json = yield call([response, 'json']);
  const {docs, totalDocs, limit, canSort} = json;
  return {response, docs, totalDocs, limit, canSort};
}

function* loadImages({resolve, reject}) {
  try {
    const isLoading = yield select(getIsLoading);
    const hasMore = yield select(getHasMore);
    const labels = yield select(getSelectedLabels);
    if (isLoading || !hasMore) {
      return;
    }
    yield put(gallery.Creators.setLoading(true));

    const sortByLikesOffset = yield select(getTotalFetched);
    const offset = yield select(getOffset);

    const {response, docs, totalDocs, canSort} = yield call(getImages, {offset, labels, sortByLikesOffset});
    if (response.ok) {
      yield put(gallery.Creators.addImages(docs ? Object.values(docs) : [], totalDocs, canSort));
    }

    yield put(gallery.Creators.setLoading(false));
    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.error(e);
    if (reject) {
      reject(e);
    }
  }
}

function* loadLabels({resolve, reject}) {
  try {
    yield put(gallery.Creators.setLoading(true));
    const token = yield select(getBearerToken);

    const response = yield call(fetch, API.GET_LABELS, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      encoding: null,
    });
    const json = yield call([response, 'json']);
    const {docs} = json;
    if (response.ok) {
      yield put(gallery.Creators.setLabels(docs ? Object.values(docs) : []));
    }
    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.error(e);
    if (reject) {
      reject(e);
    }
  }
}

function* reloadImages({resolve, reject}) {
  try {
    const labels = yield select(getSelectedLabels);
    yield put(gallery.Creators.setLoading(true));
    const {response, docs, totalDocs, canSort} = yield call(getImages, {labels});
    if (response.ok) {
      yield put(gallery.Creators.setImages(docs ? Object.values(docs) : [], totalDocs, canSort));
      yield put(gallery.Creators.setNewTotal(totalDocs));
    }

    yield put(gallery.Creators.setLoading(false));
    window.scrollTo(0, 0);
    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.error(e);
    if (reject) {
      reject(e);
    }
  }
}

export function* checkNewImages({delayMS = FIRST_CHECK_DELAY}) {
  const {next} = yield race({
    next: delay(delayMS),
    route: take(routerActionTypes.TRANSITION_START),
  });
  if (next) {
    const labels = yield select(getSelectedLabels);
    const {response, totalDocs} = yield call(getImages, {limit: 1, labels});
    if (response.ok) {
      yield put(gallery.Creators.setNewTotal(totalDocs));
    }
    yield put(gallery.Creators.checkNewImages(CHECK_DELAY));
  }
}

export function* deleteImage({id}) {
  const token = yield select(getBearerToken);
  yield call(fetch, API.IMAGE, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({id}),
  });
  const shouldSortByLikes = yield select(shouldSortImagesByLikes);
  yield put(gallery.Creators.reloadImages(shouldSortByLikes));
}

export function* likeImage({imageId, userId}) {
  const likedImages = yield select(getLikedImageIds);
  if (likedImages.has(imageId)) {
    return;
  }

  const imagesWithPendingLikeStatusChange = yield select(getImagesWithPendingLikeStatusChange);
  // if we are still finishing some previous image like - this is to prevent getting into incorrect state if user would furiously click
  // on like/unlike
  if (imagesWithPendingLikeStatusChange.find(id => id === imageId) !== undefined) {
    return;
  }

  yield put(user.Creators.setImageWithPendingLikeStatusChange(imageId));

  const token = yield select(getBearerToken);
  const response = yield call(fetch, API.LIKE_IMG({imageId, userId}), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  // Revert the UI change caused by the reducers if like failed on the server
  // based on the server response
  if (response.ok) {
    yield put(gallery.Creators.incrementImageLikes(imageId));
  } else {
    // If there was a conflict (code 409) then it means that this user already liked that image
    if (response.status === 409) {
      // Since UI got into incorrect state we need to use the image likes count from the server
      // and also add the image to users liked images
      const {imageLikesCount, userLiked} = yield call([response, 'json']);
      yield put(gallery.Creators.setImageLikesCount(imageId, imageLikesCount));
      if (userLiked) {
        yield put(user.Creators.addLikedImageId(imageId));
      } else {
        yield put(user.Creators.removeLikedImageId(imageId));
      }
    }
  }
}

export function* unlikeImage({imageId, userId}) {
  const likedImages = yield select(getLikedImageIds);
  if (!likedImages.has(imageId)) {
    return;
  }

  const imagesWithPendingLikeStatusChange = yield select(getImagesWithPendingLikeStatusChange);
  // if we are still finishing some previous image unlike - this is to prevent getting into incorrect state if user would furiously click
  // on like/unlike
  if (imagesWithPendingLikeStatusChange.find(id => id === imageId) !== undefined) {
    return;
  }
  yield put(user.Creators.setImageWithPendingLikeStatusChange(imageId));

  const token = yield select(getBearerToken);
  const response = yield call(fetch, API.UNLIKE_IMG({imageId, userId}), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  // Revert the UI change caused by the reducers if like failed on the server
  if (response.ok) {
    yield put(gallery.Creators.decrementImageLikes(imageId));
  } else {
    if (response.status === 409) {
      // 409 means that the like was already removed on the server
      // Since UI count got into incorrect state we need to use the image likes count from the server
      const {imageLikesCount, userLiked} = yield call([response, 'json']);
      yield put(gallery.Creators.setImageLikesCount(imageId, imageLikesCount));
      if (userLiked) {
        yield put(user.Creators.addLikedImageId(imageId));
      } else {
        yield put(user.Creators.removeLikedImageId(imageId));
      }
    }
  }
}

function* reloadImageLikesForFetchedImages() {
  const imagesWithPendingLikeStatusChange = yield select(getImagesWithPendingLikeStatusChange);
  // if something is being liked right now we don't want to refetch total likes to not get into
  // possible incorrect count state
  if (imagesWithPendingLikeStatusChange.length !== 0) {
    return;
  }

  try {
    const labels = yield select(getSelectedLabels);
    const sortByLikes = yield select(shouldSortImagesByLikes);
    // We want to reload likes for the complete set of currently displayed set of images
    const limit = yield select(getTotalFetched);
    const maxDate = yield select(getOffset);
    const token = yield select(getBearerToken);
    const response = yield call(
      fetch,
      `${API.GET_IMAGES_LIKES_COUNT}?maxDate=${maxDate || new Date().toISOString()}&limit=${limit}&labels=${labels.join(
        ',',
      )}&sortByLikes=${sortByLikes}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        encoding: null,
      },
    );
    const {docs} = yield call([response, 'json']);

    if (response.ok) {
      yield put(gallery.Creators.setImagesLikes(docs ? Object.values(docs) : []));
      yield put(user.Creators.updateLikedImageIds(docs ? Object.values(docs) : []));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* refetchImageLikes({delayMS = FIRST_CHECK_DELAY}) {
  const {next} = yield race({
    next: delay(delayMS),
    route: take(routerActionTypes.TRANSITION_START),
  });

  if (next) {
    yield call(reloadImageLikesForFetchedImages, {});
    yield put(gallery.Creators.refetchImageLikes(IMAGE_LIKES_FETCH_DELAY));
  }
}

function* onSortByLikesPreferenceChanged() {
  yield call(reloadImages, {});
}

export function* gallerySaga() {
  yield takeEvery(gallery.Types.CHECK_NEW_IMAGES, checkNewImages);
  yield takeEvery(gallery.Types.REFETCH_IMAGE_LIKES, refetchImageLikes);
  yield takeEvery(gallery.Types.LOAD_IMAGES, loadImages);
  yield takeEvery(gallery.Types.LOAD_LABELS, loadLabels);
  yield takeEvery(gallery.Types.RELOAD_IMAGES, reloadImages);
  yield takeEvery(gallery.Types.DELETE_IMAGE, deleteImage);
  yield takeEvery(gallery.Types.LIKE_IMAGE, likeImage);
  yield takeEvery(gallery.Types.UNLIKE_IMAGE, unlikeImage);
  yield takeEvery(gallery.Types.SET_SHOULD_SORT_BY_LIKES, onSortByLikesPreferenceChanged);
}
