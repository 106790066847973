import React, {useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Navigation} from './Navigation';
import {CropEditor} from './CropEditor';
import {actions} from '../../actions/photoBooth';
import {getGroupCrop} from '../../selectors/photoBooth';

const getEditedGroupPhoto = (img, rect) => {
  const ratio = img.naturalWidth / img.width;
  const [offsetX, offsetY, width, height] = [rect.left * ratio, rect.top * ratio, rect.width * ratio, rect.height * ratio];

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, offsetX, offsetY, width, height, 0, 0, width, height);

  return canvas.toDataURL();
};

export const CropPhotoView = ({img, onContinue, onGoBack}) => {
  const dispatch = useDispatch();
  const imgRef = useRef({});
  const rect = useSelector(getGroupCrop);

  const updateGroupPhoto = () => dispatch(actions.Creators.setEditedGroupPhoto(getEditedGroupPhoto(imgRef.current, rect)));
  return (
    <div className="editor group">
      <Navigation onNavigate={updateGroupPhoto} />
      <CropEditor img={img} imgRef={imgRef} />
      <div className="editor-controls">
        <button className="button--retake" type="button" onClick={onGoBack}>
          Restart
        </button>
        <button
          className="button--next"
          type="button"
          onClick={() => {
            updateGroupPhoto();
            onContinue();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};
