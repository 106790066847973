import React, {useRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {isIosDevice, isAndroidDevice, isNativeApp, isMobileApp} from '@staffbase/plugins-client-sdk';
import {ThemeProvider} from 'emotion-theming'; // TODO emotion / theming
import {Gallery} from './gallery/Gallery';
import {PhotoBooth} from './photo-booth/PhotoBooth';
import {getRoute} from './selectors/route';
import {getTheme} from './selectors/theme';
import {actions as platformActions} from './actions/platform';

import './styles/main.scss';
import {useAsyncState} from './components/useAsyncState';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const getRoutedElement = ({route}) => {
  switch (route && route.name) {
    case 'root':
    case 'gallery':
      return <Gallery />;
    case 'photobooth':
      return <PhotoBooth />;
    default:
      return <PhotoBooth />;
  }
};

export default function App() {
  const route = useSelector(getRoute);
  const theme = useSelector(getTheme);
  const main = useRef();

  const dispatch = useDispatch();

  const [isNative] = useAsyncState(false, isNativeApp);
  const [isIos] = useAsyncState(false, isIosDevice);
  const [isAndroid] = useAsyncState(false, isAndroidDevice);
  const [isMobile] = useAsyncState(null, isMobileApp);

  useEffect(() => {
    dispatch(platformActions.Creators.setPlatformParams(isNative, isIos, isAndroid, isMobile));
  }, [isNative, isIos, isAndroid, isMobile, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <main ref={main} className={`${isIos && isNative ? 'iosNative ' : ''} ${route.name}`}>
        {getRoutedElement({route})}
      </main>
    </ThemeProvider>
  );
}
