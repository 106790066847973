import React, {useState, useEffect, useLayoutEffect} from 'react';
import {useSelector} from 'react-redux';
import {AutoSizer, WindowScroller, Grid, InfiniteLoader, CellMeasurer, CellMeasurerCache} from 'react-virtualized';

import {Like} from '../components/Like';
import {getTotal} from '../selectors/gallery';
import {THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH} from './const';

export const Thumbnails = ({setActiveImage, isDetailOpened, toggleLike, isLiked, images, loadMoreItems}) => {
  const total = useSelector(getTotal);

  const [trackGalleryWidth, setTrackGalleryWidth] = useState(true);
  useLayoutEffect(() => {
    let trackEnableTimeout = null;
    // Stop tracking width immediately when lightbox is opened
    if (isDetailOpened) {
      setTrackGalleryWidth(!isDetailOpened);
    } else {
      // We want to start tracking the width again only once the lightbox animation finishes
      trackEnableTimeout = setTimeout(() => {
        setTrackGalleryWidth(!isDetailOpened);
      }, 150);
    }
    return () => {
      if (trackEnableTimeout !== null && trackEnableTimeout !== undefined) {
        clearTimeout(trackEnableTimeout);
      }
    };
  }, [isDetailOpened]);

  const [galleryWidth, setGalleryWidth] = useState(window.screen.width);
  const [columnCount, setColumnCount] = useState(2);
  useEffect(() => {
    setColumnCount(Math.max(Math.ceil(galleryWidth / THUMBNAIL_WIDTH), 2));
  }, [galleryWidth]);

  const [columnWidth, setColumnWidth] = useState(THUMBNAIL_WIDTH);
  const [columnHeight, setColumnHeight] = useState(THUMBNAIL_HEIGHT);
  useEffect(() => {
    const newWidth = Math.max(Math.round(galleryWidth / columnCount), 1);
    setColumnWidth(newWidth);
    setColumnHeight(Math.round((newWidth / THUMBNAIL_WIDTH) * THUMBNAIL_HEIGHT));
  }, [galleryWidth, columnCount]);

  const [rowsCount, setRowsCount] = useState(0);
  useEffect(() => {
    setRowsCount(Math.ceil(total / columnCount));
  }, [total, columnCount]);

  const [list, setList] = useState([[]]);
  useEffect(() => {
    setList(
      images.reduce((all, one, i) => {
        const ch = Math.floor(i / columnCount);
        all[ch] = [].concat(all[ch] || [], one);
        return all;
      }, []),
    );
  }, [images, columnCount]);

  const handleGalleryWidthChange = width => {
    if (trackGalleryWidth && width !== galleryWidth) {
      // The measured width is for some reason off by 1px Oo
      setGalleryWidth(width + 1);
    }
  };

  return (
    <div className="gallery-body">
      <InfiniteLoader isRowLoaded={({index}) => !!images[index]} loadMoreRows={loadMoreItems} threshold={5} rowCount={total}>
        {({onRowsRendered, registerChild}) => (
          <WindowScroller>
            {({height, isScrolling, scrollTop}) => (
              <AutoSizer disableHeight>
                {({width}) =>
                  handleGalleryWidthChange(width) || (
                    <Grid
                      ref={registerChild}
                      cellRenderer={cell({
                        list,
                        images,
                        isLiked,
                        toggleLike,
                        setActiveImage,
                        columnWidth,
                        columnHeight,
                      })}
                      columnCount={columnCount}
                      columnWidth={columnWidth}
                      height={height}
                      rowCount={rowsCount}
                      rowHeight={columnHeight}
                      width={galleryWidth}
                      onSectionRendered={({columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex}) => {
                        const startIndex = rowStartIndex * columnCount + columnStartIndex;
                        const stopIndex = rowStopIndex * columnCount + columnStopIndex;
                        onRowsRendered({startIndex, stopIndex});
                      }}
                      isScrolling={isScrolling}
                      scrollTop={scrollTop}
                      autoHeight
                    />
                  )
                }
              </AutoSizer>
            )}
          </WindowScroller>
        )}
      </InfiniteLoader>
    </div>
  );
};

const cellMeasurerCache = new CellMeasurerCache({
  defaultHeight: THUMBNAIL_HEIGHT,
  defaultWidth: THUMBNAIL_WIDTH,
  fixedWidth: true,
});

const cell = ({list, setActiveImage, images, isLiked, toggleLike, columnWidth, columnHeight}) => ({
  columnIndex,
  rowIndex,
  index,
  key,
  parent,
  style,
  isVisible,
}) => {
  const image = (list[rowIndex] || [])[columnIndex];
  const {thumbnailUrl, id, imageLikesCount} = image || {};
  return (
    <CellMeasurer cache={cellMeasurerCache} index={index} key={key} parent={parent}>
      <div
        style={{
          ...style,
        }}
      >
        {thumbnailUrl && (
          <div
            className="gallery-cell"
            style={{
              margin: 10,
              height: 'calc(100% - 20px)',
            }}
          >
            {isVisible && (
              <>
                <img
                  key={thumbnailUrl}
                  onClick={() => {
                    setActiveImage({id, index: images.findIndex(image => image.id === id)});
                  }}
                  src={thumbnailUrl}
                  style={{height: columnHeight - 60, width: columnWidth}}
                  alt=""
                />

                <Like
                  likesCount={imageLikesCount}
                  onToggleLike={() => {
                    toggleLike(id);
                  }}
                  liked={isLiked(id)}
                />
              </>
            )}
          </div>
        )}
      </div>
    </CellMeasurer>
  );
};
