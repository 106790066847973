import {takeEvery, call, put, select} from 'redux-saga/effects';

import {actions} from '../actions/photoBooth';
import {
  getEditedGroupPhoto,
  getFormat,
  getGroupCrop,
  getId,
  getImage,
  getMessage,
  getOverlay,
  getPosition,
  getSelectedLabels,
  getShareData,
} from '../selectors/photoBooth';
import {getBearerToken, getTags, getUserName} from '../selectors/auth';
import {getIsAndroid, getIsIos, getIsNative} from '../selectors/platform';
import {SPECIAL_FORMAT} from '../photo-booth/picture-editor/constants';

import {API} from './constants';

const DEFAULT_ERROR = 'Something went wrong while uploading the picture, please try again.';

function* uploadImg({image}) {
  yield put(actions.Creators.setLoading(true));

  const token = yield select(getBearerToken);

  const formData = new FormData();
  formData.append('image_file', image);
  const fetchResult = yield call(fetch, API.UPLOAD_IMG, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    encoding: null,
  });

  const blobResult = yield call([fetchResult, 'blob']);
  const imageId = fetchResult.headers.get('X-Image-Id');
  const urlCreator = window.URL || window.webkitURL;
  const imageSrc = urlCreator.createObjectURL(blobResult);
  yield put(actions.Creators.setImage('', imageSrc));
  yield put(actions.Creators.setLoading(false));

  // Users don't have to wait for the id update
  yield put(actions.Creators.updateImage({id: imageId}));
}

function* saveEditedImage() {
  const id = yield select(getId);
  const overlay = yield select(getOverlay);
  const format = yield select(getFormat);
  const image = yield select(getImage);
  const message = yield select(getMessage);
  const position = yield select(getPosition);
  const groupPhoto = yield select(getEditedGroupPhoto);
  const groupCrop = yield select(getGroupCrop);
  const labels = yield select(getSelectedLabels);

  const {fullName, teamName, jobTitle, entity, externally, location} = yield select(getShareData);

  const token = yield select(getBearerToken);

  yield put(actions.Creators.setImageUploading(true));

  const ios = yield select(getIsIos);
  const native = yield select(getIsNative);
  const android = yield select(getIsAndroid);
  const fetchResult = yield call(fetch, API.SAVE_EDITED_IMG, {
    method: 'POST',
    body: JSON.stringify({
      id,
      overlay: format === SPECIAL_FORMAT ? overlay : null,
      image,
      message,
      fullName,
      teamName,
      jobTitle,
      entity,
      location,
      position,
      externally,
      groupPhoto,
      groupCrop,
      labels,
      device: {
        ios,
        native,
        android,
        height: (document && document.documentElement && document.documentElement.clientHeight) || window.innerHeight,
        width: (document && document.documentElement && document.documentElement.clientWidth) || window.innerWidth,
        userAgent: navigator.userAgent,
      },
    }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (fetchResult.ok) {
    yield put(actions.Creators.setUploadError(''));
  } else {
    yield put(actions.Creators.setUploadError(DEFAULT_ERROR));
  }
  yield put(actions.Creators.setImageUploading(false));
}

function* loadLabels({resolve, reject}) {
  try {
    const token = yield select(getBearerToken);

    const response = yield call(fetch, API.GET_NORMAL_LABELS, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      encoding: null,
    });
    const json = yield call([response, 'json']);
    const {docs} = json;
    if (response.ok) {
      yield put(actions.Creators.setLabels(docs ? Object.values(docs) : []));
    }
    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.error(e);
    if (reject) {
      reject(e);
    }
  }
}

function* loadSpecialLabel({resolve, reject}) {
  try {
    const token = yield select(getBearerToken);

    const response = yield call(fetch, API.GET_CURRENT_SPECIAL_LABEL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      encoding: null,
    });
    const json = yield call([response, 'json']);
    const {docs} = json;
    if (response.ok) {
      yield put(actions.Creators.setSpecialLabel(docs && Object.values(docs).length > 0 ? Object.values(docs)[0] : undefined));
    }
    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.error(e);
    if (reject) {
      reject(e);
    }
  }
}

export function* updateUserData() {
  const fullName = yield select(getUserName);
  const tags = yield select(getTags);
  const location = [tags && tags.cityfortags, tags && tags.countryfortags].filter(Boolean).join(', ');
  const jobTitle = (tags && tags.positionfortags) || '';
  yield put(actions.Creators.updateShareData({fullName, jobTitle, location}));
}

export function* photoBoothSaga() {
  yield takeEvery(actions.Types.LOAD_PHOTOBOOTH_LABELS, loadSpecialLabel);
  yield takeEvery(actions.Types.LOAD_PHOTOBOOTH_LABELS, loadLabels);
  yield takeEvery(actions.Types.SAVE_IMAGE, saveEditedImage);
  yield takeEvery(actions.Types.UPLOAD_IMG, uploadImg);
  yield takeEvery(actions.Types.RESTART, updateUserData);
}
