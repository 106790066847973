import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import posed from 'react-pose';
import {actions} from '../actions/photoBooth';
import {NORMAL_FORMAT, SPECIAL_FORMAT} from './picture-editor/constants';

import posterNormalPhoto from '../assets/img/poster-normal-photo.png';
import posterDisabled from '../assets/img/posterDisabled.png';
import {getSpecialLabel} from '../selectors/photoBooth';

const PosedListItem = posed.div({
  enter: {
    opacity: 1,
    delay: 200,
    transition: {duration: 150},
    applyAtStart: {display: 'block'},
  },
  exit: {
    opacity: 0,
    // delay: 200,
    // transition: { duration: 150 },
    applyAtStart: {display: 'none'},
  },
});

export const PickFormat = ({onContinue}) => {
  const [format, setFormat] = useState();
  const label = useSelector(getSpecialLabel);

  const dispatch = useDispatch();
  useEffect(() => {
    // Reset the app state and keep this step
    dispatch(actions.Creators.restart({step: 1}));
    dispatch(actions.Creators.loadPhotoboothLabels()); // TODO called also on intro
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section>
      <div>
        <h1 className="h-center">Select photo type</h1>

        <div className="cards">
          <div
            className={`card ${format === SPECIAL_FORMAT ? ' selected' : !label && ' disabled'}`}
            onClick={() => setFormat(SPECIAL_FORMAT)}
          >
            <img src={label ? label.badgeUrl : posterDisabled} alt="" />
            <h4 className={`${!label ? 'disabled' : ''}`}>Special Photo</h4>
          </div>

          <div className={`card ${format === NORMAL_FORMAT ? ' selected' : ''}`} onClick={() => setFormat(NORMAL_FORMAT)}>
            <img src={posterNormalPhoto} alt="" />
            <h4>Normal Photo</h4>
          </div>
        </div>

        <PosedListItem pose={format === SPECIAL_FORMAT ? 'enter' : 'exit'}>
          {/* <ul className="list-steps">
            <li className="list-steps-photo">Take photo</li>
            <li className="list-steps-text">Add text</li>
          </ul> */}
          <h4 className="h-mt-20">{label && label.title}</h4>
          <p>
            {label
              ? label.description
              : 'Currently there is no special photo event. Corporate Marketing will inform you as soon as an event is upcoming.'}
          </p>
        </PosedListItem>
        <PosedListItem pose={format === NORMAL_FORMAT ? 'enter' : 'exit'}>
          {/* <ul className="list-steps">
            <li className="list-steps-photo">Take photo</li>
            <li className="list-steps-overlay">Apply overlay</li>
            <li className="list-steps-text">Add text</li>
          </ul> */}
          <p>
            Take a photo of whatever you would like to share with the company. E. g. a project photo, a trade fair photo or a photo of an
            event with your colleagues.
          </p>
        </PosedListItem>
        <button
          disabled={label ? !format : format !== NORMAL_FORMAT}
          className="button button--primary h-mt-30 h-mb-30"
          type="button"
          onClick={() => onContinue(format)}
        >
          Create your photo
        </button>
      </div>
    </section>
  );
};
