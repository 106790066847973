import React, {useState, useEffect} from 'react';
import Modal from '../components/Modal';
import posed, {PoseGroup} from 'react-pose';
import {useDispatch} from 'react-redux';
import {actions} from '../actions/photoBooth';
import {Link} from 'react-router5';

const PosedInfo = posed.div({
  show: {
    y: 0,
    opacity: 1,
    delay: 100,
    transition: {duration: 200},
  },
  hide: {
    y: -20,
    opacity: 0,
    transition: {duration: 200},
  },
});

const PosedModal = posed(Modal)({
  enter: {
    y: 0,
    opacity: 1,
    delay: 100,
    transition: {duration: 200},
  },
  exit: {
    y: 20,
    opacity: 0,
    transition: {duration: 150},
  },
});

const Shade = posed.div({
  enter: {opacity: 1},
  exit: {opacity: 0},
});

export const Intro = ({onContinue}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (dispatch) {
      dispatch(actions.Creators.loadPhotoboothLabels());
    }
  }, [dispatch]);

  const [isOpen, setOpen] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isOpenExternally, setOpenExternally] = useState(false);
  const [isCheckedExternally, setCheckedExternally] = useState(false);

  return (
    <section className="intro-page">
      <div className="intro-page-heading h-center">
        <h1>Welcome to our Photo App</h1>
        <p>
          Create your photo and post it in our photo gallery. For example, share interesting photos from your BayWa r.e. everyday life or
          take part in special photo activities. <Link routeName="gallery">Open the gallery</Link> for inspiration.
        </p>
      </div>
      <div className="intro-page-tos">
        <PosedInfo pose={isChecked ? 'show' : 'hide'} className="info-box">
          Please be careful with whom you're sharing photos.
        </PosedInfo>
        <div className="checkbox-wrapper checkbox-wrapper--multiline">
          <input className="checkbox" type="checkbox" onChange={() => setChecked(!isChecked)} checked={isChecked} />
          <span onClick={() => setChecked(!isChecked)}>I have read and agree to the</span>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={() => setOpen(!isOpen)}> terms and conditions</a>.
        </div>
        <div className="checkbox-wrapper checkbox-wrapper--multiline">
          <input
            className="checkbox"
            type="checkbox"
            onChange={() => setCheckedExternally(!isCheckedExternally)}
            checked={isCheckedExternally}
          />
          <span onClick={() => setCheckedExternally(!isCheckedExternally)}>
            To support BayWa r.e. marketing, selected images may also to be used for external communications. I have read and agree to the
          </span>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={() => setOpenExternally(!isOpenExternally)}> terms and conditions for external usage</a>.
        </div>
        <button
          className="button button--primary h-mt-10"
          onClick={() => {
            dispatch(actions.Creators.setConditionsChecked(isChecked));
            dispatch(actions.Creators.updateShareData({externally: isCheckedExternally}));
            onContinue();
          }}
          disabled={!isChecked}
        >
          Create your photo
        </button>
      </div>
      <PoseGroup>
        {(isOpen || isOpenExternally) && <Shade key="shade" className="modal-shade" />}
        {isOpen && (
          <PosedModal key="modal" closeModal={() => setOpen(false)}>
            <h2 className="h-mb-30 h-pr-30">Terms and Conditions</h2>
            <p>
              By using the r.e.source photo app, you agree that the photo and text you create might be used by Corporate Communications for
              internal communication purposes (e.g. using photos of team activities in our r.e.source magazine, using a project photo in our
              r.e.source app or using a special event photo on shared office screens).
            </p>
            <p>
              After the photo has been taken and edited, it will be published in the r.e.source photo gallery once you click the “save”
              button. This gallery is visible to all colleagues worldwide. Photos taken with your account can be deleted with the same
              account by going into the gallery, selecting the respective photo and clicking the three vertical dots and "Delete".
            </p>
          </PosedModal>
        )}
        {isOpenExternally && (
          <PosedModal key="modal" closeModal={() => setOpenExternally(false)}>
            <h2 className="h-mb-30 h-pr-30">Terms and Conditions for External Usage</h2>
            <p>
              By allowing its usage for external communications, you agree that the photo and text you create using the r.e.source photo app
              might be used by Corporate Communications for external communication purposes (e.g. using a project photo for press releases
              or a special activity photo to create a common gallery which is shared on Social Media). This supports Corporate
              Communications in marketing the BayWa r.e. brand.
            </p>
            <p>
              After the photo has been taken and edited, it will be published in the r.e.source photo gallery once you click the “save”
              button. This gallery is visible to all colleagues worldwide. Photos taken with your account can be deleted with the same
              account by going into the gallery, selecting the respective photo and clicking the three vertical dots and "Delete".
            </p>
            <p>
              If you happen to appear on a photo without your consent, you can always let us know, by opening the respective photo and
              clicking the three vertical dots and "Report photo".
            </p>
          </PosedModal>
        )}
      </PoseGroup>
    </section>
  );
};
