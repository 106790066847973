import {combineReducers} from 'redux';
import {router5Reducer} from 'redux-router5';
import {authReducer as auth} from './auth';
import {photoBoothReducer as photoBooth} from './photoBooth';
import {galleryReducer as gallery} from './gallery';
import {userReducer as user} from './user';
import {platformReducer as platform} from './platform';

export const rootReducer = combineReducers({
  router: router5Reducer,
  auth,
  photoBooth,
  gallery,
  user,
  platform,
});
