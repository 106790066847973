import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Link} from 'react-router5';
import posed, {PoseGroup} from 'react-pose';
import {useSelector, useDispatch} from 'react-redux';
import {useScrollPosition} from '@n8tb1t/use-scroll-position';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {useOnClickOutside} from '../helpers/useOnClickOutside';
import {useLockBodyScroll} from '../helpers/useLockBodyScroll';
import {Lightbox} from '../components/Lightbox';
import {actions} from '../actions/gallery';
import {actions as userActions} from '../actions/user';
import {getLikedImageIds} from '../selectors/user';
import {
  getImages,
  getHasMore,
  getNewItemsCount,
  getLabels,
  getSelectedLabels,
  canSortImages,
  getTotal,
  shouldSortImagesByLikes,
} from '../selectors/gallery';
import {getUserId} from '../selectors/auth';
import {getIsMobile} from '../selectors/platform';

import {GallerySlider} from './GallerySlider';
import {Thumbnails} from './Thumbnails';

const PosedLightbox = posed(Lightbox)({
  enter: {
    y: 0,
    opacity: 1,
    scale: 1,
    delay: 50,
    transition: {duration: 250},
  },
  exit: {
    y: 50,
    opacity: 0,
    scale: 0.95,
    transition: {duration: 150},
  },
});

export const Gallery = () => {
  const isMobile = useSelector(getIsMobile);
  // {
  //   id: string,
  //   index: number
  // }
  const [activeImage, setActiveImage] = useState(null);

  const escFunction = useCallback(event => {
    if (event.keyCode === 27) {
      setActiveImage(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.Creators.loadImages());
    dispatch(actions.Creators.loadLabels());
    dispatch(actions.Creators.checkNewImages());
    dispatch(actions.Creators.refetchImageLikes());
  }, [dispatch]);

  const [showOnScroll, setShowOnScroll] = useState(true);
  useScrollPosition(
    ({currPos}) => {
      if (!isMobile) {
        return;
      }

      const isShow = currPos.y > -100;
      setShowOnScroll(isShow);
    },
    [showOnScroll, isMobile],
    null,
    false,
    0,
  );
  const sortImagesByLikes = useSelector(shouldSortImagesByLikes);
  const images = useSelector(getImages);
  const canSortImagesByLikes = useSelector(canSortImages);

  const totalImagesCount = useSelector(getTotal);
  const hasMore = useSelector(getHasMore);
  useEffect(() => {
    if (hasMore && activeImage && images.length - 3 < activeImage.index) {
      dispatch(actions.Creators.loadImages());
    }
  }, [activeImage, hasMore, images, dispatch]);

  const loadMoreItems = useCallback(
    () =>
      new Promise(function (resolve, reject) {
        dispatch(actions.Creators.loadImages(resolve, reject));
      }),
    [dispatch],
  );

  const userId = useSelector(getUserId);
  const likedImageIds = useSelector(getLikedImageIds);
  const newItemsCount = useSelector(getNewItemsCount);

  const filterPopup = useRef();
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const labels = useSelector(getLabels);
  const selectedLabels = useSelector(getSelectedLabels);
  const [originalFilter, setOriginalFilter] = useState({labels: [], initialized: false});

  useEffect(() => {
    dispatch(userActions.Creators.getLikedImageIds());
  }, [userId, dispatch]);

  // Check if filter data changed once the filter gets closed
  useEffect(() => {
    if (isFilterVisible || !originalFilter.initialized) {
      return;
    }

    const {labels: initialLabels} = originalFilter;
    const changed = initialLabels.length !== selectedLabels.length || initialLabels.some(e => !selectedLabels.includes(e));
    if (changed) {
      dispatch(actions.Creators.reloadImages(sortImagesByLikes));
    }
    setOriginalFilter({labels: [], initialized: false});
  }, [originalFilter, selectedLabels, isFilterVisible, dispatch, sortImagesByLikes]);

  // Initialize filter with default values once it gets opened
  useEffect(() => {
    if (!isFilterVisible || originalFilter.initialized) {
      return;
    }

    setOriginalFilter({labels: selectedLabels, initialized: true});
  }, [isFilterVisible, selectedLabels, originalFilter]);

  const onCloseLightBox = useCallback(() => {
    setActiveImage(null);
  }, []);

  useOnClickOutside(filterPopup, () => {
    setIsFilterVisible(false);
  });

  const toggleLike = useCallback(
    imageId => {
      if (likedImageIds.has(imageId)) {
        dispatch(actions.Creators.unlikeImage(imageId, userId));
      } else {
        dispatch(actions.Creators.likeImage(imageId, userId));
      }
    },
    [likedImageIds, dispatch, userId],
  );

  const isLiked = useCallback(imageId => likedImageIds.has(imageId), [likedImageIds]);

  const filterRef = useLockBodyScroll({disableScroll: isMobile && isFilterVisible});
  const lightboxRef = useLockBodyScroll({disableScroll: activeImage !== null});

  useEffect(() => {
    if (activeImage && lightboxRef.current) {
      document.getElementsByClassName('slick-active')[0].focus();
    }
  }, [activeImage, lightboxRef]);

  const renderFilter = (
    <div
      className={`gallery-filter-modal ${isMobile ? 'gallery-filter-modal--mobile' : 'gallery-filter-modal--desktop'}`}
      ref={filterPopup}
    >
      {isMobile && (
        <button
          type="button"
          className={`button--close button--close-dark`}
          onClick={() => {
            setIsFilterVisible(false);
          }}
        />
      )}
      <ul ref={filterRef}>
        <li onClick={() => dispatch(actions.Creators.setSelectedLabels([]))}>
          <div className="checkbox-wrapper">
            <input readOnly className="checkbox" type="checkbox" checked={selectedLabels.length === 0} />
            <span>
              <label>All</label>
            </span>
          </div>
        </li>
        {labels.map(label => (
          <li
            key={label.id}
            className={selectedLabels.includes(label.id) ? 'selected' : ''}
            onClick={() => {
              dispatch(actions.Creators.toggleLabel(label.id));
            }}
          >
            <div className="checkbox-wrapper">
              <input readOnly className="checkbox" type="checkbox" checked={selectedLabels.includes(label.id)} />
              <span>
                <label>{label.name}</label>
              </span>
            </div>
          </li>
        ))}
      </ul>
      <div className="gallery-filter-modal-button">
        <button
          className={`button button--primary`}
          onClick={() => {
            setIsFilterVisible(current => !current);
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );

  return (
    <section className="gallery">
      {isMobile && (
        <>
          <Link
            className={`button button--primary button--fixed button--photobooth ${showOnScroll ? '' : ' hidden'}`}
            routeName="photobooth"
          >
            <i />
          </Link>
          <button
            className={`button button--primary button--fixed button--filter ${showOnScroll ? '' : ' hidden'}`}
            onClick={() => setIsFilterVisible(current => !current)}
          >
            <i />
          </button>
        </>
      )}
      <div className="gallery-header">
        <h1 className="h-flex-center">
          Photo Gallery
          {isMobile && (
            <Link className="button button--secondary button--small" routeName="photobooth">
              Open Photo App
            </Link>
          )}
        </h1>
      </div>
      {!!newItemsCount && (
        <button
          className="button button--secondary button--small button--new"
          onClick={() => dispatch(actions.Creators.reloadImages(sortImagesByLikes))}
        >
          {`${newItemsCount} new picture${newItemsCount > 1 ? 's' : ''}`}
        </button>
      )}

      <div className="gallery-filter">
        <button
          className={`button button--secondary button--small button--filter h-mr-10 h-mb-10`}
          onClick={() => setIsFilterVisible(current => !current)}
        >
          Filters ({selectedLabels.length})
        </button>

        {selectedLabels.map(value => (
          <button
            key={value}
            className={`button button--small button--tag`}
            onClick={() => {
              dispatch(actions.Creators.toggleLabel(value));
              dispatch(actions.Creators.reloadImages(sortImagesByLikes));
            }}
          >
            {labels.find(label => label.id === value).name}
            <span />
          </button>
        ))}
        <PoseGroup>{isMobile && isFilterVisible && <PosedLightbox key="lightbox">{renderFilter}</PosedLightbox>}</PoseGroup>
        {!isMobile && isFilterVisible && <>{renderFilter}</>}
      </div>

      <div className="gallery-sort">
        {canSortImagesByLikes && (
          <div
            className="checkbox-wrapper"
            onClick={() => {
              dispatch(actions.Creators.setShouldSortByLikes(!sortImagesByLikes));
            }}
          >
            <input readOnly className="checkbox" type="checkbox" checked={sortImagesByLikes} />
            <span>
              <label>Sort images by number of likes</label>
            </span>
          </div>
        )}
      </div>

      {likedImageIds !== null && (
        <Thumbnails
          images={images}
          isDetailOpened={activeImage !== null}
          setActiveImage={setActiveImage}
          toggleLike={toggleLike}
          loadMoreItems={loadMoreItems}
          isLiked={isLiked}
        />
      )}

      <PoseGroup>
        {activeImage !== null && [
          <PosedLightbox ref={lightboxRef} key="lightbox">
            <GallerySlider
              totalImagesCount={totalImagesCount}
              images={images}
              userId={userId}
              enableAutoPlay={canSortImagesByLikes}
              onClose={onCloseLightBox}
              toggleLike={toggleLike}
              isLiked={isLiked}
              activeImage={activeImage}
              onSliderImageChange={setActiveImage}
            />
          </PosedLightbox>,
        ]}
      </PoseGroup>
    </section>
  );
};
