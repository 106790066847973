export const throttled = (fn, ms) => {
  fn.lastInvocation = 0;
  return (...args) => {
    clearTimeout(fn.timeout);

    const invoke = () => {
      fn(...args);
      fn.lastInvocation = Date.now();
    };

    if (!fn.lastInvocation || Date.now() - fn.lastInvocation > ms) {
      invoke();
    } else {
      fn.timeout = setTimeout(invoke, ms - (Date.now() - fn.lastInvocation));
    }
  };
};
