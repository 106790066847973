import React, {useState, useCallback, useRef} from 'react';
import Slider from 'react-slick';
import {useSelector, useDispatch} from 'react-redux';
import {CountdownCircleTimer} from 'react-countdown-circle-timer';

import {getIsMobile} from '../selectors/platform';
import {actions} from '../actions/gallery';

import {GallerySliderImage} from './GallerySliderImage';

const LOADED_DETAILS = 5;
const IMAGE_CHANGE_DURATION_MS = 5000;

export const GallerySlider = ({
  userId,
  enableAutoPlay,
  images,
  totalImagesCount,
  onSliderImageChange,
  onClose,
  activeImage,
  toggleLike,
  isLiked,
}) => {
  const dispatch = useDispatch();
  const isMobile = useSelector(getIsMobile);

  const sliderRef = useRef();
  const [sliderPlaying, setSliderPlaying] = useState(true);

  const toggleAutoPlay = useCallback(play => {
    if (play !== undefined) {
      setSliderPlaying(play);
    } else {
      setSliderPlaying(current => !current);
    }
  }, []);

  const onDeleteImage = useCallback(
    image => {
      if ((image.creator && userId === image.creator) || image.originalImageUrl) {
        dispatch(actions.Creators.deleteImage(image.id));
      }
    },
    [userId, dispatch],
  );

  return (
    <>
      {!isMobile && enableAutoPlay && (
        <div className="gallery-autoPlay">
          <CountdownCircleTimer
            // timer restarts when the "key" prop is changed
            key={activeImage.id}
            size={50}
            // Countdown is going as long as the slider is also playing
            isPlaying={sliderPlaying}
            duration={IMAGE_CHANGE_DURATION_MS / 1000}
            trailColor="transparent"
            colors={'#78b90f'}
            strokeWidth={6}
            onComplete={() => {
              sliderRef.current.slickNext();
            }}
          >
            {() => (
              <button
                className={sliderPlaying ? 'button--pause' : 'button--play'}
                type="button"
                onClick={() => {
                  toggleAutoPlay();
                }}
              />
            )}
          </CountdownCircleTimer>
          {/* Numbers for now commented out, because there is a bug with sliders "afterChange" which gets
              noticeable when numbers are displayed */}
          {/* <p>
            {activeImage.index + 1}/{totalImagesCount}
          </p> */}
        </div>
      )}

      <Slider
        ref={sliderRef}
        infinite
        arrows={!isMobile}
        lazyLoad="progressive"
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        waitForAnimate
        variableWidth={false}
        variableHeight={false}
        // TODO AfterChange is not properly called every time when switching with arrows
        afterChange={index => {
          onSliderImageChange({id: images[index].id, index});
        }}
        // NOTE: AutoPlay does not work reliably (initial start delay, sometimes does not start at all, ...)
        // So we have own autoplay controlled by the countdown timer
        autoplay={false}
        className="slides"
        initialSlide={activeImage.index}
        useTransform={false}
      >
        {images.map((image, index) => (
          <GallerySliderImage
            key={image.id}
            image={image}
            imageSource={
              Math.abs(activeImage.index - index) < LOADED_DETAILS || index === images.length - 1 || index === 0
                ? image.smallUrl || image.fullUrl
                : null
            }
            onDelete={onDeleteImage}
            onToggleAutoPlay={toggleAutoPlay}
            onClose={onClose}
            isLiked={isLiked}
            toggleLike={toggleLike}
          />
        ))}
      </Slider>
    </>
  );
};

export default GallerySlider;
