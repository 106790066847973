export const convertTagsToObject = (tags = []) =>
  tags.reduce((res, tag) => {
    const tagValues = tag.split(':');
    return tagValues.length === 3 ? {...res, [tagValues[1]]: getTagValue(tagValues[2])} : res;
  }, {});

const getTagValue = originalValue => {
  const fixedAll = fixByDictionary(originalValue, ALL);
  if (fixedAll !== originalValue) {
    return fixedAll;
  }
  return fixByDictionary(
    originalValue
      .split('-')
      .filter(Boolean)
      .map(word => fixByDictionary(word.charAt(0).toUpperCase() + word.slice(1), WORDS))
      .join(' '),
    SUBSTRING,
  );
};

const fixByDictionary = (text, mode) =>
  dictionary
    .filter(term => mode === term.mode)
    .reduce(
      (fixedText, term) =>
        mode !== SUBSTRING
          ? fixedText === term.searchValue
            ? term.newValue
            : fixedText
          : fixedText.replace(new RegExp(term.searchValue, 'g'), term.newValue),
      text,
    );

const WORDS = 'words';
const SUBSTRING = 'substring';
const ALL = 'all';

export const dictionary = [
  {
    searchValue: 'Of',
    newValue: 'of',
    mode: WORDS,
  },
  {
    searchValue: 'Other',
    newValue: '(Other)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'E Commerce',
    newValue: 'E-Commerce',
    mode: SUBSTRING,
  },
  {
    searchValue: 'And',
    newValue: 'and',
    mode: WORDS,
  },
  {
    searchValue: 'Pv',
    newValue: 'PV',
    mode: WORDS,
  },
  {
    searchValue: 'Emea',
    newValue: 'EMEA',
    mode: WORDS,
  },
  {
    searchValue: 'Ppa',
    newValue: 'PPA',
    mode: WORDS,
  },
  {
    searchValue: 'Emobility',
    newValue: 'eMobility',
    mode: WORDS,
  },
  {
    searchValue: 'Novotegra',
    newValue: 'novotegra',
    mode: WORDS,
  },
  {
    searchValue: 'Nl',
    newValue: 'NL',
    mode: WORDS,
  },
  {
    searchValue: 'Hr',
    newValue: 'HR',
    mode: WORDS,
  },
  {
    searchValue: 'B2c',
    newValue: 'B2C',
    mode: WORDS,
  },
  {
    searchValue: 'Om',
    newValue: 'O&M',
    mode: SUBSTRING,
  },
  {
    searchValue: 'O M',
    newValue: 'O & M',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Spain Portugal',
    newValue: 'Spain & Portugal',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Denkmark Sweden',
    newValue: 'Denkmark & Sweden',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Sea',
    newValue: 'SEA',
    mode: WORDS,
  },
  {
    searchValue: 'Apac',
    newValue: 'APAC',
    mode: WORDS,
  },
  {
    searchValue: 'Apac',
    newValue: 'APAC',
    mode: WORDS,
  },
  {
    searchValue: 'Scada',
    newValue: 'SCADA',
    mode: WORDS,
  },
  {
    searchValue: 'Coo',
    newValue: 'COO',
    mode: WORDS,
  },
  {
    searchValue: 'B2b',
    newValue: 'B2B',
    mode: WORDS,
  },
  {
    searchValue: 'Tubingen',
    newValue: 'Tübingen',
    mode: WORDS,
  },
  {
    searchValue: 'Munchen',
    newValue: 'München',
    mode: WORDS,
  },
  {
    searchValue: 'Dusseldorf',
    newValue: 'Düsseldorf',
    mode: WORDS,
  },
  {
    searchValue: 'Malmo',
    newValue: 'Malmö',
    mode: WORDS,
  },
  {
    searchValue: 'Ciudad De Mexico',
    newValue: 'Ciudad de México',
    mode: WORDS,
  },
  {
    searchValue: 'Bb Nijmegen',
    newValue: 'BB Nijmegen',
    mode: WORDS,
  },
  {
    searchValue: 'Tokyo',
    newValue: 'Tōkyō',
    mode: WORDS,
  },
  {
    searchValue: 'South Korea',
    newValue: '(South Korea)',
    mode: WORDS,
  },
  {
    searchValue: 'Tll',
    newValue: 'TLL',
    mode: WORDS,
  },
  {
    searchValue: 'Team Leader IT',
    newValue: 'Team Leader (IT)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Manager It Sevice Teamlead It Erp Administrations Manager',
    newValue: 'Manager IT-Service / Teamlead IT & ERP Administrations Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'On Site Service',
    newValue: 'On-Site-Service',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Site Assessment Expert Technical Manager',
    newValue: 'Site Assessment Expert – Technical Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Cad',
    newValue: 'CAD',
    mode: WORDS,
  },
  {
    searchValue: 'Agri Pv',
    newValue: 'Agri-PV',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Finance Processes System Manager',
    newValue: 'Finance Processes & System Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Global Director of Services Director of Segment Support Services',
    newValue: 'Global Director of Services & Director of Segment Support Services',
    mode: SUBSTRING,
  },
  {
    searchValue: 'External It Consultant',
    newValue: 'External IT Consultant',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Intern PV Int',
    newValue: 'Intern PV Int.',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Strategy Market Analysis Manager',
    newValue: 'Strategy & Market Analysis Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Project Manager Pv Int',
    newValue: 'Project Manager PV int.',
    mode: SUBSTRING,
  },
  {
    searchValue: 'It Apprentice',
    newValue: 'IT Apprentice',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Strategy Market Analysis Manager',
    newValue: 'Strategy & Market Analysis Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Project Manager Pv Int',
    newValue: 'Project Manager PV int.',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Country Director Wind Projects Benelux',
    newValue: 'Country Director, Wind Projects, Benelux',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of It Front office',
    newValue: 'Head of IT – Front office',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of It Front office',
    newValue: 'Head of IT – Front office',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Team Leader Finance Controlling',
    newValue: 'Team Leader Finance & Controlling',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Team Leader Portfolio Management Trading',
    newValue: 'Team Leader Portfolio Management & Trading',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Project Manager Wind Int',
    newValue: 'Project Manager Wind Int.',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Client Customer Relations Manager',
    newValue: 'Client & Customer Relations Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'It Administrator',
    newValue: 'IT-Administrator',
    mode: SUBSTRING,
  },
  {
    searchValue: 'External It Consultant',
    newValue: 'External IT Consultant',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Director of Corporate Strategy Energy Policy Sustainability',
    newValue: 'Director of Corporate Strategy, Energy Policy & Sustainability',
    mode: SUBSTRING,
  },
  {
    searchValue: 'It Purchasing Manager',
    newValue: 'IT Purchasing Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Coordinator Sales Marketing',
    newValue: 'Coordinator Sales & Marketing',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Global HR Development Recruiting',
    newValue: 'Head of Global HR Development & Recruiting',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Global Director of Wind Projects Director of Segment Support Projects',
    newValue: 'Global Director of Wind Projects & Director of Segment Support Projects',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of New Business External Investors',
    newValue: 'Head of New Business & External Investors',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Epc',
    newValue: 'EPC',
    mode: WORDS,
  },
  {
    searchValue: 'Head of Solar Proxy Holder',
    newValue: 'Head of Solar, Proxy Holder',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Representative Director Coo',
    newValue: 'Representative Director, COO',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Bd',
    newValue: 'BD',
    mode: WORDS,
  },
  {
    searchValue: 'It License Manager',
    newValue: 'IT License Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'C I Origination Manager',
    newValue: 'C&I Origination Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Senior Expert Pv Revamping Repowering',
    newValue: 'Senior Expert PV Revamping / Repowering',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of It Technical Back office',
    newValue: 'Head of IT – Technical Back office',
    mode: SUBSTRING,
  },
  {
    searchValue: 'It Project Manager',
    newValue: 'IT Project Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Inspection Operations Manager',
    newValue: 'Inspection & Operations Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Finance Be Services',
    newValue: 'Head of Finance BE Services',
    mode: SUBSTRING,
  },
  {
    searchValue: 'It Demand Manager',
    newValue: 'IT Demand Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Director of Corporate Marketing Comms',
    newValue: 'Director of Corporate Marketing & Comm',
    mode: SUBSTRING,
  },
  {
    searchValue: 'It Security Manager',
    newValue: 'IT Security Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Itlead Software Architect Change',
    newValue: 'Head of IT/Lead Software Architect & Change',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Risk Manager Group Controller',
    newValue: 'Risk Manager & Group Controller',
    mode: SUBSTRING,
  },
  {
    searchValue: 'External It Consultant',
    newValue: 'External IT Consultant',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Representative Director President',
    newValue: 'Representative Director, President',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Assistant Teamproject',
    newValue: 'External IT Consultant',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Team Gf Assistenz',
    newValue: 'Team- & GF-Assistenz',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Warranty Manager & Quality and Environmental Management officer – Pv Power Plants',
    newValue: 'Warranty Manager Quality And Environmental Management officer PV Power Plants',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Software Development Support',
    newValue: 'Software Development & Support',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Team Leader Engineering',
    newValue: 'Team Leader (Engineering)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of It Planning Delivery',
    newValue: 'Head of IT – Planning & Delivery',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Working Student PM',
    newValue: 'Working Student PM',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Director of Corporate It',
    newValue: 'Director of Corporate IT',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Project Manager Mit',
    newValue: 'Project Manager MIT',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Communications Change Manager',
    newValue: 'Communications & Change Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Managing Director At BayWa Re Energy Ventures',
    newValue: 'Managing Director at BayWa r.e. Energy Ventures',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Crm It Interface Process Design Manager',
    newValue: 'CRM, IT Interface & Process Design Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Draftsman CAD Expert',
    newValue: 'Draftsman / CAD Expert',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Servicesquality Management',
    newValue: 'Head of Services/Quality Management',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Ifrs Advisory',
    newValue: 'Head of IFRS Advisory',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Director of Legal General Counsel',
    newValue: 'Director of Legal / General Counsel',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Assistant To Sales Manager',
    newValue: 'Assistant to Sales Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Ceo',
    newValue: 'CEO',
    mode: WORDS,
  },
  {
    searchValue: 'Pm Team Assistant',
    newValue: 'PM Team Assistant',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Team MD Assistant',
    newValue: 'Team & MD Assistant',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Project Manager Product Manager Floating Pv',
    newValue: 'Project Manager & Product Manager Floating PV',
    mode: SUBSTRING,
  },
  {
    searchValue: 'External It Consultant',
    newValue: 'External IT Consultant',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Team Project Assistant',
    newValue: 'Team & Project Assistant',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of It Hub Site Management',
    newValue: 'Head of IT – Hub & Site Management',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Cfo',
    newValue: 'CFO',
    mode: WORDS,
  },
  {
    searchValue: 'Praktikant Marketingstrategien Eventmanagement',
    newValue: 'Praktikant Marketingstrategien & Eventmanagement',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Director of Solar Projects EMEA Managing Director',
    newValue: 'Director of Solar Projects EMEA & Managing Director',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Accounting Controlling',
    newValue: 'Head of Accounting & Controlling',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Operations Maintenance Supervisor',
    newValue: 'Operations & Maintenance Supervisor',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Counsel Legal',
    newValue: 'Counsel (Legal)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Return Transit Fulfillment Intern',
    newValue: 'Return, Transit & Fulfillment Intern',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Manager Site Assessmentsenior Project Developer',
    newValue: 'Manager Site Assessment/Senior Project Developer',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Executive Assistantmarketing Coordinator',
    newValue: 'Executive Assistant/Marketing Coordinator',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Civils Landscaping',
    newValue: 'Head of Civils & Landscaping',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Area Manager Sap',
    newValue: 'Area Manager / SAP',
    mode: SUBSTRING,
  },
  {
    searchValue: 'It Operations Specialist',
    newValue: 'IT & Operations Specialist',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Legal Advisor Legal',
    newValue: 'Legal advisor (Legal)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Storer Chihuahua',
    newValue: 'Storer (Chihuahua)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Executive Assistantmarketing Coordinator',
    newValue: 'Executive Assistant/Marketing Coordinator',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Storer Chihuahua',
    newValue: 'Storer Chihuahua',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Distributionlogistics',
    newValue: 'Distribution/Logistics',
    mode: WORDS,
  },
  {
    searchValue: 'Storer Merida',
    newValue: 'Storer (Merida)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Sales Marketing Admin',
    newValue: 'Sales & Marketing Admin',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Area Sales Product Manager',
    newValue: 'Area Sales & Product Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Sales Representative Be',
    newValue: 'Sales Representative BE',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Administrator Marketingcommunicationpr',
    newValue: 'Administrator (Marketing/Communication/PR)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Administrator Finance',
    newValue: 'Administrator (Finance)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Qhse',
    newValue: 'QHSE',
    mode: WORDS,
  },
  {
    searchValue: 'Managerspecialist Service',
    newValue: 'Manager/Specialist (Service)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Global Purchasing Solar Trade Director of Segment Support Solutions',
    newValue: 'Head of Global Purchasing Solar Trade & Director of Segment Support Solutions',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Sales Representative Fr',
    newValue: 'Sales Representative FR',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Draftswoman CAD Expert',
    newValue: 'Draftswoman / CAD Expert',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Accounting Tax Application Controlling',
    newValue: 'Accounting, Tax & Application Controlling',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Team leader Extra Works Additional Services',
    newValue: 'Team leader Extra Works & Additional Services',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of High Voltage Solar',
    newValue: 'Team leader Extra Works & Additional Services',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Team leader Extra Works Additional Services',
    newValue: 'Team leader Extra Works & Additional Services',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Civils Landscape Contract Manager',
    newValue: 'Civils & Landscape Contract Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Assistant Teamproject Assistance',
    newValue: 'Assistant Team/Project (Assistance)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Customer Support Logistics',
    newValue: 'Customer Support & Logistics',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Project Manager Hybrid Storage',
    newValue: 'Project Manager Hybrid & Storage',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Global Director of Energy Solutions Director of Segment Support Solutions',
    newValue: 'Global Director of Energy Solutions & Director of Segment Support Solutions',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Strategy Energy Policy Sustainability Manager',
    newValue: 'Strategy, Energy Policy & Sustainability Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Director of Finance Controlling',
    newValue: 'Director of Finance & Controlling',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Sr Specialist Supply Chain',
    newValue: 'Sr. Specialist, Supply Chain',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Team leader Extra Works Additional Services',
    newValue: 'Team leader Extra Works & Additional Services',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Management HR Assistant',
    newValue: 'Management & HR Assistant',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Administrator Finance',
    newValue: 'Administrator (Finance)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Temporary Employee From Personnel Agency Other',
    newValue: 'Temporary Employee (from personnel agency) (Other)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Civil Engineer Tech Dept',
    newValue: 'Civil Engineer / Tech. Dept.',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Paralegal Legal',
    newValue: 'Paralegal (Legal)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Responsible Storage Systems Benelux Product Management',
    newValue: 'Responsible Storage Systems Benelux & Product Management',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Managerspecialist Projectprocess Management',
    newValue: 'Manager/Specialist (Project/Process Management)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Administrator Accounting',
    newValue: 'Administrator (Accounting)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Managerspecialist Accounting',
    newValue: 'Manager/Specialist (Accounting)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Administrator Finance',
    newValue: 'Administrator (Finance)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Ac Engineer',
    newValue: 'AC Engineer',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Ci Business Development Manager',
    newValue: 'C&I Business Development Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Senior Expert Pv Revamping Repowering',
    newValue: 'Senior Expert PV Revamping / Repowering',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Ci Origination Manager',
    newValue: 'C&I Origination Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Managerspecialist Business Development',
    newValue: 'Manager/Specialist (Business Development)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Procurement Purchasing',
    newValue: 'Procurement (Purchasing)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Ap',
    newValue: 'A/P',
    mode: WORDS,
  },
  {
    searchValue: 'Managing Director BayWa Re Clean Energy Srl',
    newValue: 'Managing Director BayWa r.e. Clean Energy S.r.l.',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Administration Controlling',
    newValue: 'Head of Administration & Controlling',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Director of Digital Marketing Ecommerce',
    newValue: 'Director of Digital Marketing & E-Commerce',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Authorized officer Head of HR',
    newValue: 'Authorized officer / Head of HR',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Logistics Services',
    newValue: 'Head of Logistics & Services',
    mode: SUBSTRING,
  },
  {
    searchValue: 'National Warehouse Logistics Manager',
    newValue: 'National Warehouse & Logistics Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Managerspecialist HR',
    newValue: 'Manager/Specialist (HR)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Risk Quality Management',
    newValue: 'Head of Risk & Quality Management',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Legal Counsel Project Manager',
    newValue: 'Legal Counsel & Project Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Administrator It',
    newValue: 'Administrator (IT)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Partner Online Sales',
    newValue: 'Head of Partner- & Online Sales',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Administrator Purchasing',
    newValue: 'Administrator (Purchasing)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Managerspecialist Projectprocess Management',
    newValue: 'Manager/Specialist (Project/Process Management)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Global Director of Solar Projects Managing Director',
    newValue: 'Global Director of Solar Projects & Managing Director',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Senior Technical Manager Wind',
    newValue: 'Senior Technical Manager (Wind)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of R D',
    newValue: 'Administrator (IT)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Director of Wind Projects EMEA Managing Director',
    newValue: 'Director of Wind Projects EMEA & Managing Director',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Responsible Storage Systems Fr Product Management',
    newValue: 'Responsible Storage Systems FR & Product Management',
    mode: WORDS,
  },
  {
    searchValue: 'It Application Support',
    newValue: 'IT & Application Support',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Network Security Architect Itot',
    newValue: 'Network Security Architect IT/OT',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Managerspecialist Finance',
    newValue: 'Manager/Specialist (Finance)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Managerspecialist Project Legal',
    newValue: 'Manager/Specialist (Project Legal)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Director of Corporate Organisation Change',
    newValue: 'Director of Corporate Organisation & Change',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Sales Representative Lube',
    newValue: 'Sales Representative LU/BE',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Finance HR',
    newValue: 'Head of Finance & HR',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Methods Models',
    newValue: 'Head of Methods & Models',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Managerspecialist Engineering',
    newValue: 'Manager/Specialist (Engineering)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Finance Controlling APAC',
    newValue: 'Head of Finance & Controlling APAC',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Finance Sales Manager Africa',
    newValue: 'Finance & Sales Manager Africa',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Operation Maintenance Technician Assistant',
    newValue: 'Operation & Maintenance Technician Assistant',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Administrator Projectprocess Management',
    newValue: 'Head of Finance & HR',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Director of Solar Trade Amer CEO',
    newValue: 'Director of Solar Trade AMER & CEO',
    mode: SUBSTRING,
  },
  {
    searchValue: 'SCADA Engineer Project Manager',
    newValue: 'SCADA Engineer / Project Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Site Assessment Expert Technical Manager',
    newValue: 'Site Assessment Expert – Technical Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Site Assessment Expert Deputy Technical Manager',
    newValue: 'Site Assessment Expert – Deputy Technical Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Control Centre Team Leaderprocurement Specialist',
    newValue: 'Control Centre Team Leader/Procurement Specialist',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Head of Finance Controlling Americas',
    newValue: 'Head of Finance & Controlling Americas',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Administrator Sales',
    newValue: 'Administrator (Sales)',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Executive Assistant Assistant To The Board',
    newValue: 'Executive Assistant, Assistant to the Board',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Business Development Manager Consultant',
    newValue: 'Business Development Manager - Consultant',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Finance Accounting Manager',
    newValue: 'Finance & Accounting Manager',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Debitor Accounting Administration',
    newValue: 'Debitor Accounting & Administration',
    mode: SUBSTRING,
  },
  {
    searchValue: 'General Accounting Reporting',
    newValue: 'Head of Finance & Controlling Americas',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Customer Support Logistics',
    newValue: 'Customer Support & Logistics',
    mode: SUBSTRING,
  },
  {
    searchValue: 'Director of Segment Support Projects Managing Director',
    newValue: 'Director of Segment Support Projects & Managing Director',
    mode: SUBSTRING,
  },
];
