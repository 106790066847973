import {createActions} from 'reduxsauce';

export const actions = createActions(
  {
    setImage: ['id', 'src'],
    setOpacity: ['opacity'],
    pickOverlay: ['src'],
    setLoading: ['loading'],
    updateOverlay: ['overlayParams'],
    updateImage: ['imageParams'],
    saveImage: null,
    nextStep: null,
    setStep: ['step'],
    prevStep: null,
    setOverlaySelected: ['selected'],
    setMessage: ['message'],
    updateShareData: ['data'],
    setImageUploading: ['imageUploading'],
    setUploadError: ['uploadError'],
    setFormat: ['format'],
    uploadImg: ['image'],
    resetEditor: null,
    setPosition: ['position'],
    setGroupCrop: ['groupCrop'],
    setEditedGroupPhoto: ['editedGroupPhoto'],
    restart: ['data'],
    updateSliderPositions: ['sliderPositions'],
    setConditionsChecked: ['conditionsChecked'],
    setFetchInProgress: ['fetchInProgress'],
    setPreviewDimensions: ['previewDimensions'],
    loadPhotoboothLabels: [],
    setLabels: ['labels'],
    setSpecialLabel: ['specialLabel'],
    toggleLabel: ['label'],
  },
  {prefix: 'photoBooth'},
);
