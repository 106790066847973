import {createSelector} from 'reselect';

export const getGallery = state => state.gallery;

export const getLabels = createSelector(getGallery, state => [...state.labels].sort((a, b) => a.orderNum - b.orderNum));

export const getSelectedLabels = createSelector(getGallery, state => state.selectedLabels);

export const getImages = createSelector(getGallery, state => state.images);

export const getOffset = createSelector(getImages, state => (state.length ? state[state.length - 1].createdAt : new Date().toISOString()));

export const getTotal = createSelector(getGallery, state => state.total);

export const getTotalFetched = createSelector(getImages, state => (state.length ? state.length : 0));

export const getIsLoading = createSelector(getGallery, state => state.loading);

export const getNewTotal = createSelector(getGallery, state => state.newTotal);

export const canSortImages = createSelector(getGallery, state => state.canSort);

export const shouldSortImagesByLikes = createSelector(getGallery, state => state.shouldSortByLikes);

export const getNewItemsCount = createSelector(getTotal, getNewTotal, (total, newTotal) => Math.max(newTotal - total, 0));

export const getHasMore = createSelector(getTotal, getImages, (total, images) => !total || total > images.length);
