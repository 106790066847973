import {createReducer} from 'reduxsauce';

import {actions} from '../actions/auth';

const INITIAL_STATE = {
  bearer: {token: ''},
  token: '',
};

const setAuthTokenHandler = (state, {token}) => ({...state, token});
const setBearerTokenHandler = (state, {bearer}) => ({...state, bearer});

export const HANDLERS = {
  [actions.Types.SET_AUTH_TOKEN]: setAuthTokenHandler,
  [actions.Types.SET_BEARER_TOKEN]: setBearerTokenHandler,
};

export const authReducer = createReducer(INITIAL_STATE, HANDLERS);
