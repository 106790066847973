import {createSelector} from 'reselect';
import {SPECIAL_FORMAT} from '../photo-booth/picture-editor/constants';

export const getPhotoBooth = state => state.photoBooth;

export const getImage = createSelector(getPhotoBooth, booth => booth.image);

export const getOpacity = createSelector(getPhotoBooth, booth => booth.overlay.opacity);

export const getOverlay = createSelector(getPhotoBooth, booth => booth.overlay);

export const getLoading = createSelector(getPhotoBooth, booth => booth.loading);

export const getMessage = createSelector(getPhotoBooth, booth => booth.message);

export const getConditionsChecked = createSelector(getPhotoBooth, booth => booth.conditionsChecked);

export const getStep = createSelector(getPhotoBooth, getConditionsChecked, ({step}, condition) => (!step && condition ? 1 : step));

export const getId = createSelector(getPhotoBooth, booth => booth.image.id);

export const getShareData = createSelector(getPhotoBooth, booth => booth.shareData);

export const getIsShareDataValid = createSelector(
  getShareData,
  state => state.location.trim() && (state.teamName.trim() || (state.fullName.trim() && state.jobTitle.trim() && state.entity.trim())),
);

export const getUploadError = createSelector(getPhotoBooth, booth => booth.uploadError);

export const getImageUploading = createSelector(getPhotoBooth, booth => booth.imageUploading);

export const getFormat = createSelector(getPhotoBooth, booth => booth.format);

export const getPosition = createSelector(getPhotoBooth, booth => booth.position);

export const getGroupCrop = createSelector(getPhotoBooth, booth => booth.groupCrop);

export const getEditedGroupPhoto = createSelector(getPhotoBooth, booth => booth.editedGroupPhoto);

export const getSliderPositions = createSelector(getPhotoBooth, booth => booth.sliderPositions);

export const getFetchInProgress = createSelector(getPhotoBooth, booth => booth.fetchInProgress);

export const getPreviewDimensions = createSelector(getPhotoBooth, booth => booth.previewDimensions);

export const getSelectedLabels = createSelector(getPhotoBooth, getFormat, (booth, format) =>
  format === SPECIAL_FORMAT ? [booth.specialLabel.id] : booth.selectedLabels,
);

export const getSpecialLabel = createSelector(getPhotoBooth, booth => booth.specialLabel);

export const getLabels = createSelector(getPhotoBooth, booth => [...booth.labels].sort((a, b) => a.orderNum - b.orderNum));
