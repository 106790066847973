import React from 'react';
import {OverlayEditor} from './OverlayEditor';
import {Navigation} from './Navigation';

export const ApplyBadgeView = ({overlay, onContinue, onGoBack}) => {
  return (
    <div className="editor single">
      <Navigation />
      <h1 className="h-center h-mt-20">Add special activity badge</h1>
      <p className="h-center h-ml-20 h-mr-20">Resize and move the badge to the desired position.</p>
      <OverlayEditor />
      <div className="editor-controls">
        <button className="button--previous" type="button" onClick={onGoBack}>
          Previous
        </button>
        <button className="button--next" type="button" disabled={!overlay} onClick={onContinue}>
          Next
        </button>
      </div>
    </div>
  );
};
