import {createReducer} from 'reduxsauce';

import {actions} from '../actions/platform';

const INITIAL_STATE = {
  isNative: false,
  isIos: false,
  isAndroid: false,
  isMobile: null,
};

const setPlatformParams = (state, {isNative, isIos, isAndroid, isMobile}) => ({...state, isNative, isIos, isAndroid, isMobile});

export const HANDLERS = {
  [actions.Types.SET_PLATFORM_PARAMS]: setPlatformParams,
};

export const platformReducer = createReducer(INITIAL_STATE, HANDLERS);
