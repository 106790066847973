import React from 'react';
import {connect} from 'react-redux';
import {CropTool} from './CropTool';
import {getEditedGroupPhoto, getGroupCrop} from '../../selectors/photoBooth';
import {actions} from '../../actions/photoBooth';

class GroupEditorContainer extends React.Component {
  state = {
    imgDimensions: {width: 0, height: 0},
  };
  defaultPosition = {};

  componentDidMount() {
    const {left, top, width, height} = this.props.groupCrop;

    if (width) {
      this.defaultPosition = {x: left, y: top, width, height};
    } else {
      this.defaultPosition = {};
    }
  }

  updateCrop = ({currentTarget: rect}) => {
    const left = Math.round(rect.x());
    const top = Math.round(rect.y());

    // scaleX and scaleY are the variables changing during transform
    const width = Math.round(rect.width() * rect.scaleX());
    const height = Math.round(rect.height() * rect.scaleY());

    this.props.setGroupCrop({left, top, width, height});
  };

  onImgLoad = ({currentTarget}) => {
    const imgDimensions = {
      width: currentTarget.clientWidth,
      height: currentTarget.clientHeight,
    };

    let rectWidth = imgDimensions.width;
    let rectHeight = (rectWidth * 9) / 16;
    if (rectHeight > imgDimensions.height) {
      const ratio = imgDimensions.height / rectHeight;
      rectHeight = imgDimensions.height;
      rectWidth = rectWidth * ratio;
    }

    if (!this.defaultPosition.width) {
      this.defaultPosition = {
        x: (imgDimensions.width - rectWidth) / 2,
        y: (imgDimensions.height - rectHeight) / 2,
        width: rectWidth,
        height: rectHeight,
      };
    }

    this.setState({imgDimensions});

    const {x: left, y: top, width, height} = this.defaultPosition;
    this.props.setGroupCrop({left, top, width, height});
  };

  render() {
    const {img, readOnly, groupCrop, imgRef, editedGroupPhoto, containerProps} = this.props;
    const {imgDimensions} = this.state;

    const {left, top, width, height} = groupCrop;
    const clipPath = [top, imgDimensions.width - (left + width), imgDimensions.height - (top + height), left].map(val => `${val}px`);

    if (readOnly) {
      return (
        <div {...containerProps}>
          <img alt="edited" className="group-editor--photo" src={editedGroupPhoto} />
        </div>
      );
    }

    return (
      <div className="group-editor" {...containerProps}>
        <div className="group-editor--container">
          <img
            alt="clipped"
            ref={imgRef}
            className="group-editor--photo"
            onLoad={this.onImgLoad}
            src={img}
            style={{
              clipPath: `inset(${clipPath.join(' ')})`,
              WebkitClipPath: `inset(${clipPath.join(' ')})`,
            }}
          />
          {/* Background image so that clipPath has the surrounding only a bit transparent instead of black*/}
          <img
            alt="background"
            className="group-editor--photo"
            src={img}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: 0.5,
            }}
          />
          {!readOnly && (
            <CropTool
              stageProps={{
                width: imgDimensions.width,
                height: imgDimensions.height,
                style: {
                  position: 'absolute',
                  top: 0,
                },
              }}
              // Default overlay position set only once
              // TODO: Default position in the middle with proper aspect ratio
              position={this.defaultPosition}
              onSelectionChange={this.updateCrop}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  groupCrop: getGroupCrop(state),
  editedGroupPhoto: getEditedGroupPhoto(state),
});

const mapDispatchToProps = dispatch => ({
  setGroupCrop: crop => dispatch(actions.Creators.setGroupCrop(crop)),
});

export const CropEditor = connect(mapStateToProps, mapDispatchToProps)(GroupEditorContainer);
