import {Layer, Stage} from 'react-konva';
import React from 'react';
import {SelectionRectangle} from './SelectionRectangle';

const getRectDragBound = (maxWidth, maxHeight) =>
  function ({x, y}) {
    return {
      x: Math.max(0, Math.min(x, maxWidth - this.width() * this.scaleX())),
      y: Math.max(0, Math.min(y, maxHeight - this.height() * this.scaleY())),
    };
  };

const getRectTransformBound = (maxWidth, maxHeight) => (oldRect, {width, height, x, y}) => {
  const minWidth = 128;
  const minHeight = 72;
  const maxX = maxWidth;
  const maxY = maxHeight;

  if (
    x < 0 ||
    y < 0 ||
    width < minWidth ||
    width > maxWidth ||
    height < minHeight ||
    height > maxHeight ||
    x + width > maxX ||
    y + height > maxY
  ) {
    return oldRect;
  }

  return {x, y, width, height};
};

export const CropTool = ({onSelectionChange, position, stageProps}) => {
  const {width, height} = stageProps;

  return (
    <Stage className="area-selection-controls" {...stageProps}>
      <Layer>
        <SelectionRectangle
          transformBoundFn={getRectTransformBound(width, height)}
          dragBoundFn={getRectDragBound(width, height)}
          position={position}
          isSelected
          onTransform={onSelectionChange}
          onDragMove={onSelectionChange}
          transformerProps={{
            keepRatio: false,
          }}
        />
      </Layer>
    </Stage>
  );
};
