import jwtDecode from 'jwt-decode';
import {createSelector} from 'reselect';
import {convertTagsToObject} from '../helpers/convertTagsToObject';

export const getAuth = state => state.auth;

export const getAuthToken = createSelector(getAuth, ({token}) => token);

export const getDecodedAuthToken = createSelector(getAuthToken, token => (token ? jwtDecode(token) : undefined));

export const getUserId = createSelector(getDecodedAuthToken, token => (token ? token.sub : undefined));

export const getUserName = createSelector(getDecodedAuthToken, token => (token ? token.name : undefined));

export const getTags = createSelector(getDecodedAuthToken, token => (token ? convertTagsToObject(token.tags) : undefined));

export const getBearerToken = state => state.auth.bearer && state.auth.bearer.token;
