import {call, put, select} from 'redux-saga/effects';

import {actions} from '../actions/auth';

import {getTheme} from '../selectors/theme';
import {getAuthToken, getBearerToken} from '../selectors/auth';

const TOKEN_VALIDITY = 23 * 60 * 60 * 1000;

function* updateBearerToken(authToken) {
  const bearerToken = {
    token: authToken,
    expiresAt: Date.now() + TOKEN_VALIDITY,
  };
  yield put(actions.Creators.setBearerToken(bearerToken));
}

export function* storeAuthToken(route) {
  const jwt = route.params && route.params.jwt;
  if (jwt) {
    yield put(actions.Creators.setAuthToken(jwt));
    yield call(updateBearerToken, jwt);
    const theme = yield select(getTheme);
    document.documentElement.style.setProperty('--c-primary', theme.bg);
    document.documentElement.style.setProperty('--c-text', theme.text);
  }

  const bearer = yield select(getBearerToken);
  if (!bearer || bearer.expiresAt < Date.now()) {
    const storedJwt = yield select(getAuthToken);
    yield call(updateBearerToken, storedJwt);
  }
}
