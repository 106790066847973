import {createSelector} from 'reselect';

import {getDecodedAuthToken} from './auth';

const DEFAULT_THEME = {
  bg: '#bfe9ff',
  text: '#fff',
};

export const getTheme = createSelector(getDecodedAuthToken, token =>
  token
    ? {
        bg: token.theming_bg,
        text: token.theming_text,
      }
    : DEFAULT_THEME,
);
