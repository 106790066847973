import {createReducer} from 'reduxsauce';

import {actions} from '../actions/gallery';

const INITIAL_STATE = {
  images: [],
  labels: [],
  selectedLabels: [],
  total: 0,
  newTotal: 0,
  loading: false,
  canSort: false,
  shouldSortByLikes: false,
};

const addImages = (state, {images, total, canSort}) => ({
  ...state,
  images: [...state.images, ...images],
  total,
  loading: INITIAL_STATE.loading,
  canSort,
});

const setImages = (state, {images, total, canSort}) => ({...state, images, total, loading: INITIAL_STATE.loading, canSort});

const setLabels = (state, {labels}) => ({...state, labels});

const setSelectedLabels = (state, {labels}) => ({...state, selectedLabels: labels});

const toggleLabel = (state, {labelId}) => ({
  ...state,
  selectedLabels: state.selectedLabels.includes(labelId)
    ? state.selectedLabels.filter(val => val !== labelId)
    : [...state.selectedLabels, labelId],
});

const setLoading = (state, {loading}) => ({...state, loading});

const setNewTotal = (state, {newTotal}) => ({...state, newTotal});

const likeImage = (state, {imageId}) => ({
  ...state,
  images: state.images.map(image => (image.id !== imageId ? image : {...image, imageLikesCount: image.imageLikesCount + 1})),
});

const unlikeImage = (state, {imageId}) => ({
  ...state,
  images: state.images.map(image => (image.id !== imageId ? image : {...image, imageLikesCount: image.imageLikesCount - 1})),
});

const setImagesLikes = (state, {images}) => ({
  ...state,
  images: state.images.map(stateImage => {
    const newImage = images.find(newImage => newImage.id === stateImage.id);
    const newImageLikes = newImage !== undefined ? newImage.imageLikesCount : stateImage.imageLikesCount;
    return {
      ...stateImage,
      imageLikesCount: newImageLikes,
    };
  }),
});

const setImageLikesCount = (state, {imageId, imageLikesCount}) => ({
  ...state,
  images: state.images.map(stateImage =>
    stateImage.id !== imageId
      ? stateImage
      : {
          ...stateImage,
          imageLikesCount,
        },
  ),
});

const setShouldSortByLikes = (state, {shouldSortByLikes}) => ({
  ...state,
  shouldSortByLikes,
});

export const HANDLERS = {
  [actions.Types.ADD_IMAGES]: addImages,
  [actions.Types.SET_IMAGES]: setImages,
  [actions.Types.SET_LOADING]: setLoading,
  [actions.Types.SET_SHOULD_SORT_BY_LIKES]: setShouldSortByLikes,
  [actions.Types.SET_NEW_TOTAL]: setNewTotal,
  [actions.Types.SET_LABELS]: setLabels,
  [actions.Types.SET_SELECTED_LABELS]: setSelectedLabels,
  [actions.Types.TOGGLE_LABEL]: toggleLabel,
  [actions.Types.DECREMENT_IMAGE_LIKES]: unlikeImage,
  [actions.Types.INCREMENT_IMAGE_LIKES]: likeImage,
  [actions.Types.SET_IMAGES_LIKES]: setImagesLikes,
  [actions.Types.SET_IMAGE_LIKES_COUNT]: setImageLikesCount,
};

export const galleryReducer = createReducer(INITIAL_STATE, HANDLERS);
