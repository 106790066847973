import React, {useState, useRef} from 'react';
import {useSelector} from 'react-redux';

import {Like} from '../components/Like';
import {useOnClickOutside} from '../helpers/useOnClickOutside';
import {getIsMobile} from '../selectors/platform';

const getShortId = id => id.substring(0, 6);

const getFormattedDate = (date, withTime) =>
  new Date(date)
    .toLocaleString('de-DE', {
      hour12: true,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      timeZone: 'Europe/Berlin',
      ...(withTime ? {minute: '2-digit', hour: '2-digit'} : {}),
    })
    .toLowerCase()
    .replace(/\./g, '-')
    .replace(',', '');

const getEmailData = image => {
  return {
    Name: image.fullName,
    Title: image.jobTitle,
    Location: image.location,
    Labels: image.labels,
    Date: getFormattedDate(image.createdAt, true),
    ID: image.id,
    'Short ID': getShortId(image.id),
  };
};

const getEmailDataText = image => {
  const emailData = getEmailData(image);
  return Object.keys(emailData)
    .filter(key => emailData[key])
    .map(key => `- ${key}: ${emailData[key]}`)
    .join(',%0D%0A');
};

export const LightboxBody = ({imgSrc, name, extraInfo, image, onDelete, onToggleAutoPlay, closeLightbox, isLiked, toggleLike}) => {
  const [isModal, setIsModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const isMobile = useSelector(getIsMobile);

  const menuPopup = useRef();
  useOnClickOutside(menuPopup, () => setShowMenu(false));

  return (
    <>
      <div key="header" className="lightbox-header">
        <div className="lightbox-img-info">
          <p>{name}</p>
          <div className="lightbox-like-button">
            <Like
              likesCount={image.imageLikesCount}
              onToggleLike={() => {
                toggleLike(image.id);
              }}
              liked={isLiked(image.id)}
            />
          </div>
          {extraInfo && <ol className="lightbox-img-info-user">{extraInfo.map(info => info && <li key={info}>{info}</li>)}</ol>}
          {image.labels &&
            (isMobile ? (
              <ol className="lightbox-img-info-tags">
                {image.labels.slice(0, 3).map(label => (
                  <li key={label}>{label}</li>
                ))}
                {image.labels.length > 3 && <li>+ {image.labels.length - 3}</li>}
              </ol>
            ) : (
              <ol className="lightbox-img-info-tags">
                {image.labels.map(label => (
                  <li key={label}>{label}</li>
                ))}
              </ol>
            ))}
        </div>
        <div className="lightbox-buttons">
          <div className="lightbox-menu">
            <button
              className={`button button--menu`}
              onClick={() => {
                // If menu is shown we are gonna hide it and start auto play
                onToggleAutoPlay(showMenu);
                setShowMenu(!showMenu);
              }}
            />
            {showMenu && (
              <ul ref={menuPopup}>
                <li>
                  <a
                    href={`mailto:Admin.Resource@baywa-re.com?subject=Report r.e.source Gallery photo ${getShortId(
                      image.id,
                    )}&body=Dear r.e.source admin, %0D%0A%0D%0APlease delete the r.e.source Gallery photo with the data %0D%0A%0D%0A${getEmailDataText(
                      image,
                    )}%0D%0A%0D%0AThank you`}
                  >
                    Report photo
                  </a>
                </li>
                {onDelete && (
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      onClick={() => {
                        setIsModal(true);
                        setShowMenu(!showMenu);
                      }}
                    >
                      Delete
                    </a>
                  </li>
                )}
                {image.originalImageUrl && <li className="menu-heading">{getShortId(image.id)}</li>}
                {image.originalImageUrl && image.externally && <li className="menu-heading">For external usage</li>}
                {image.originalImageUrl && (
                  <li>
                    <a href={image.originalImageUrl} download={true}>
                      Original
                    </a>
                  </li>
                )}
                {image.originalImageUrl && image.hasBadge && (
                  <li>
                    <a href={image.originalImageUrl.replace('-original', '-edited')} download={true}>
                      Edited
                    </a>
                  </li>
                )}
              </ul>
            )}
          </div>
          <button type="button" className="button--close-dark" onClick={closeLightbox} />
        </div>
      </div>
      <div className="lightbox-image">
        <img key="img" src={imgSrc} alt="" />
      </div>
      <p className="lightbox-date">{getFormattedDate(image.createdAt, true)}</p>
      {isModal && (
        <div className="confirm-box" onClick={() => setIsModal(false)}>
          <p>Do you really want to delete this photo?</p>
          <div>
            <button onClick={() => setIsModal(false) || onDelete()} className="button button--primary">
              Delete
            </button>
            <button onClick={() => setIsModal(false)} className="button button--secondary">
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export const Lightbox = React.forwardRef(({imgSrc, name, extraInfo, closeLightbox, children}, ref) => {
  return (
    <div className="lightbox" ref={ref}>
      <div className="lightbox-body">{children || <LightboxBody {...{imgSrc, name, extraInfo}} />}</div>
    </div>
  );
});
