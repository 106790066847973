import React from 'react';
import {Navigation} from './Navigation';
import {useDispatch, useSelector} from 'react-redux';
import {getPosition, getShareData} from '../../selectors/photoBooth';
import {NORMAL_FORMAT} from './constants';
import {actions} from '../../actions/photoBooth';
import {OverlayEditor} from './OverlayEditor';

export const Preview = ({message, onGoBack, onSave, format, onCancel, img, fetchInProgress}) => {
  const position = useSelector(getPosition);
  const {fullName, jobTitle, entity, location} = useSelector(getShareData);
  const extraInfo = [fullName, jobTitle, entity, location];
  const buttonSave = (
    <div className="editor-preview-save">
      <button onClick={onSave} type="button" className="button--primary button--save">
        <i />
        Save to r.e.source gallery
      </button>
    </div>
  );

  const dispatch = useDispatch();
  const setImageDims = ({currentTarget}) => {
    dispatch(
      actions.Creators.updateImage({
        naturalWidth: currentTarget.naturalWidth,
        naturalHeight: currentTarget.naturalHeight,
        posterWidth: currentTarget.clientWidth,
        posterHeight: currentTarget.clientHeight,
      }),
    );
  };

  return (
    <div className="editor">
      <Navigation />
      {extraInfo && (
        <ol className="editor-preview-info">
          {extraInfo.map(
            info =>
              info && (
                <React.Fragment key={info}>
                  <li>{info}</li>
                  <span />
                </React.Fragment>
              ),
          )}
        </ol>
      )}
      <div className="editor-preview-poster-wrapper">
        <div className="editor-preview-poster">
          <div className="editor-preview-poster-aspect-ratio">
            {format === NORMAL_FORMAT ? (
              <div className="editor-overlay readOnly">
                <div className="editor-overlay-images-container">
                  <img className="editor-overlay-photo" onLoad={setImageDims} alt="preview" src={img} />
                </div>
              </div>
            ) : (
              <OverlayEditor updateImage={setImageDims} containerProps={{style: {left: position.image.left}}} readOnly />
            )}
          </div>
          <h1 className="editor-message-text">{message}</h1>
        </div>
      </div>
      {buttonSave}
      <div className="editor-controls">
        <button className="button--previous" type="button" onClick={onGoBack}>
          Previous
        </button>
      </div>
    </div>
  );
};
