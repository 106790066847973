import React from 'react';

export class Resizer extends React.Component {
  state = {
    style: {},
  };

  updateHeight = () => {
    const height = (document && document.documentElement && document.documentElement.clientHeight) || window.innerHeight;
    const width = (document && document.documentElement && document.documentElement.clientWidth) || window.innerWidth;
    this.setState({style: {height: `${Math.max(width, height)}px`}});
  };

  componentDidMount() {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight);
  }

  render() {
    return (
      <div
        className="resizer"
        {...this.props}
        // style={this.state.style}
      />
    );
  }
}
