import React from 'react';

import {LightboxBody} from '../components/Lightbox';

// Memoizing this actually makes the grid to render a bit slower
export const GallerySliderImage = ({image, imageSource, onDelete, onToggleAutoPlay, onClose, isLiked, toggleLike}) => (
  <div key={image.id} className="lightbox-body">
    <LightboxBody
      image={image}
      imgSrc={imageSource}
      name={image.fullName || image.teamName}
      extraInfo={[image.jobTitle, image.location, image.entity]}
      onDelete={() => {
        onDelete(image);
      }}
      onToggleAutoPlay={onToggleAutoPlay}
      closeLightbox={onClose}
      isLiked={isLiked}
      toggleLike={toggleLike}
    />
  </div>
);
