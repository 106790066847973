import {createReducer} from 'reduxsauce';

import {actions} from '../actions/photoBooth';
import {OVERLAY_PATHS} from '../photo-booth/picture-editor/constants';

const INITIAL_STATE = {
  overlay: {
    src: OVERLAY_PATHS[0],
    left: 0,
    top: 0,
    width: 200,
    height: 200,
    naturalWidth: 0,
    naturalHeight: 0,
    opacity: 1,
    selected: true,
  },
  image: {
    id: '',
    src: '',
    naturalHeight: 0,
    naturalWidth: 0,
    height: 0,
    width: 0,
    posterWidth: 0,
    posterHeight: 0,
  },
  message: '',
  labels: [],
  selectedLabels: [],
  loading: false,
  format: '',
  step: 0,
  shareData: {
    fullName: '',
    teamName: '',
    jobTitle: '',
    entity: '',
    location: '',
    externally: false,
  },
  imageUploading: false,
  uploadError: '',
  position: {
    image: {
      left: 0,
    },
    message: {
      left: 0,
      top: 0,
    },
  },
  groupCrop: {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  },
  editedGroupPhoto: '',
  sliderPositions: {
    x: -1,
    y: 0,
  },
  conditionsChecked: false,
  fetchInProgress: false,
  previewDimensions: {
    width: 0,
    height: 0,
  },
};
const setImage = (state, {id, src}) => ({...state, image: {...state.image, id, src}});
const setOpacity = (state, {opacity}) => ({...state, overlay: {...state.overlay, opacity}});
const pickOverlay = (state, {src}) => ({...state, overlay: {...state.overlay, src}});
const setLoading = (state, {loading}) => ({...state, loading});
const updateImage = (state, {imageParams}) => ({...state, image: {...state.image, ...imageParams}});
const nextStep = state => ({...state, step: state.step + 1});
const setStep = (state, {step}) => ({...state, step});
const prevStep = state => ({...state, step: state.step - 1});
const updateOverlay = (state, {overlayParams}) => ({...state, overlay: {...state.overlay, ...overlayParams}});
const setOverlaySelected = (state, {selected}) => ({...state, overlay: {...state.overlay, selected}});
const setMessage = (state, {message}) => ({...state, message});
const updateShareData = (state, {data}) => ({...state, shareData: {...state.shareData, ...data}});
const setImageUploading = (state, {imageUploading}) => ({...state, imageUploading});
const setUploadError = (state, {uploadError}) => ({...state, uploadError});
const setFormat = (state, {format}) => ({...state, format});
const resetEditor = state => ({...state, image: INITIAL_STATE.image, overlay: INITIAL_STATE.overlay});
const setPosition = (state, {position}) => ({...state, position});
const setGroupCrop = (state, {groupCrop}) => ({...state, groupCrop});
const setEditedGroupPhoto = (state, {editedGroupPhoto}) => ({...state, editedGroupPhoto});
const setConditionsChecked = (state, {conditionsChecked}) => ({...state, conditionsChecked});
const restart = (state, {data}) => ({
  ...INITIAL_STATE,
  conditionsChecked: state.conditionsChecked,
  shareData: {externally: state.shareData.externally},
  specialLabel: state.specialLabel,
  ...data,
});
const updateSliderPositions = (state, {sliderPositions}) => ({...state, sliderPositions: {...state.sliderPositions, ...sliderPositions}});
const setFetchInProgress = (state, {fetchInProgress}) => ({...state, fetchInProgress});
const setPreviewDimensions = (state, {previewDimensions}) => ({...state, previewDimensions});
const setLabels = (state, {labels}) => ({...state, labels});
const setSpecialLabel = (state, {specialLabel}) => ({
  ...state,
  specialLabel,
  overlay:
    specialLabel !== undefined
      ? {...state.overlay, src: specialLabel.badgeUrl, width: specialLabel.badgeWidth, height: specialLabel.badgeHeight}
      : {...state.overlay},
});
const toggleLabel = (state, {label}) => ({
  ...state,
  selectedLabels: state.selectedLabels.includes(label)
    ? state.selectedLabels.filter(value => value !== label)
    : [...state.selectedLabels, label],
});

export const HANDLERS = {
  [actions.Types.SET_IMAGE]: setImage,
  [actions.Types.SET_OPACITY]: setOpacity,
  [actions.Types.PICK_OVERLAY]: pickOverlay,
  [actions.Types.SET_LOADING]: setLoading,
  [actions.Types.UPDATE_OVERLAY]: updateOverlay,
  [actions.Types.UPDATE_IMAGE]: updateImage,
  [actions.Types.NEXT_STEP]: nextStep,
  [actions.Types.SET_STEP]: setStep,
  [actions.Types.PREV_STEP]: prevStep,
  [actions.Types.SET_OVERLAY_SELECTED]: setOverlaySelected,
  [actions.Types.SET_MESSAGE]: setMessage,
  [actions.Types.UPDATE_SHARE_DATA]: updateShareData,
  [actions.Types.SET_IMAGE_UPLOADING]: setImageUploading,
  [actions.Types.SET_UPLOAD_ERROR]: setUploadError,
  [actions.Types.SET_FORMAT]: setFormat,
  [actions.Types.RESET_EDITOR]: resetEditor,
  [actions.Types.SET_POSITION]: setPosition,
  [actions.Types.SET_GROUP_CROP]: setGroupCrop,
  [actions.Types.SET_EDITED_GROUP_PHOTO]: setEditedGroupPhoto,
  [actions.Types.RESTART]: restart,
  [actions.Types.UPDATE_SLIDER_POSITIONS]: updateSliderPositions,
  [actions.Types.SET_CONDITIONS_CHECKED]: setConditionsChecked,
  [actions.Types.SET_PREVIEW_DIMENSIONS]: setPreviewDimensions,
  [actions.Types.SET_FETCH_IN_PROGRESS]: setFetchInProgress,
  [actions.Types.SET_LABELS]: setLabels,
  [actions.Types.SET_SPECIAL_LABEL]: setSpecialLabel,
  [actions.Types.TOGGLE_LABEL]: toggleLabel,
};

export const photoBoothReducer = createReducer(INITIAL_STATE, HANDLERS);
