export const API = {
  SAVE_EDITED_IMG: '/api/saveEditedImage',
  GET_IMAGES: '/api/images',
  GET_LABELS: '/api/labels',
  GET_CURRENT_SPECIAL_LABEL: '/api/currentSpecialLabel',
  GET_NORMAL_LABELS: '/api/normalLabels',
  GET_LABELS_FOR_IMAGE: '/api/labelsForImage',
  IMAGE: '/api/image',
  UPLOAD_IMG: '/api/uploadImage',
  GET_IMAGES_LIKES_COUNT: '/api/images/likesCount',
  LIKE_IMG: ({imageId, userId}) => `/api/images/${imageId}/likes/${userId}`,
  UNLIKE_IMG: ({imageId, userId}) => `/api/images/${imageId}/likes/${userId}`,
  GET_IMG_LIKES: imageId => `/api/images/${imageId}/likes`,
  GET_USER_IMG_LIKES: userId => `/api/users/${userId}/likes`,
};
