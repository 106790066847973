import {createActions} from 'reduxsauce';

export const actions = createActions(
  {
    loadImages: ['resolve', 'reject'],
    reloadImages: ['sortByLikes', 'resolve', 'reject'],
    setImages: ['images', 'total', 'canSort'],
    addImages: ['images', 'total', 'canSort'],
    setLoading: ['loading'],
    setNewTotal: ['newTotal'],
    checkNewImages: ['delayMS'],
    refetchImageLikes: ['delayMS'],
    deleteImage: ['id'],
    loadLabels: ['resolve', 'reject'],
    setLabels: ['labels'],
    toggleLabel: ['labelId'],
    setSelectedLabels: ['labels'],
    // Made as separate action instead of using the bool param in case a "dislike" gets introduced
    likeImage: ['imageId', 'userId'],
    unlikeImage: ['imageId', 'userId'],
    incrementImageLikes: ['imageId'],
    decrementImageLikes: ['imageId'],
    setImagesLikes: ['images'],
    setImageLikesCount: ['imageId', 'imageLikesCount'],
    setShouldSortByLikes: ['shouldSortByLikes'],
  },
  {prefix: 'gallery'},
);
