import React from 'react';
import ReactDOM from 'react-dom';
import {applyMiddleware, createStore} from 'redux';
import {Provider} from 'react-redux';
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';
import {router5Middleware} from 'redux-router5';
import {RouterProvider} from 'react-router5';
import {createRouter} from 'router5';
import browserPlugin from 'router5-plugin-browser';
import {rootSaga} from './sagas';
import createSagaMiddleware from 'redux-saga';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {rootReducer} from './reducers';

// On IE11 and Edge the history API throws an 'unspecified error'
// unless the history state is reset here
window.history.replaceState(null, '', null);
const routes = [
  {name: 'root', path: '/'},
  {name: 'gallery', path: '/gallery'},
  {name: 'photobooth', path: '/photobooth'},
];

const sagaMiddleware = createSagaMiddleware();
const router = createRouter(routes, {defaultRoute: 'overview'});
router.usePlugin(browserPlugin());
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(router5Middleware(router), sagaMiddleware)));
sagaMiddleware.run(rootSaga);

router.start(() =>
  ReactDOM.render(
    <Provider store={store}>
      <RouterProvider router={router}>
        <App />
      </RouterProvider>
    </Provider>,
    document.getElementById('root'),
  ),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
