import React, {useState} from 'react';
import {NORMAL_FORMAT} from './constants';
import {Navigation} from './Navigation';
import {useSelector} from 'react-redux';
import {getSpecialLabel} from '../../selectors/photoBooth';

export const MessageEditor = ({format, img, onGoBack, onContinue, message, onMessageChanged, labels, selectedLabels, toggleLabel}) => {
  const [inputFocus, setInputFocus] = useState(false);
  const specialLabel = useSelector(getSpecialLabel);

  const renderLabels = labels.map((label, i) => (
    <li
      className={selectedLabels.includes(label.id) ? 'active' : null}
      key={label.id}
      onClick={() => document.getElementsByClassName('editor-message')[0].scrollTo({top: 0, behavior: 'smooth'}) || toggleLabel(label.id)}
    >
      {label.name}
    </li>
  ));
  const isNormalFormat = format === NORMAL_FORMAT;
  return (
    <div className="editor">
      <Navigation />
      <div className="editor-message">
        <div className={`editor-message-input ${inputFocus ? ' input-focus' : ''}`}>
          <div className="input-button">
            <textarea
              placeholder="Tap to add your text..."
              value={message}
              maxLength={100}
              onChange={e => onMessageChanged(e.target.value)}
              onFocus={() => setInputFocus(false)}
              onBlur={() => setInputFocus(false)}
            />
          </div>
          <div className={`editor-message-length ${100 - message.length === 0 ? 'full' : ''}`}>
            {100 - message.length} characters remaining...
          </div>
          {isNormalFormat ? (
            <>
              <h2 className="h-mt-30 h-center">Select Labels</h2>
              <div className="h-center">
                Labels are used to filter within the gallery.
                <wbr /> Multiple labels can be selected.
              </div>
              <ul>{renderLabels}</ul>
            </>
          ) : (
            <>
              <p className="h-strong h-mt-30">Note</p>
              Your photo will be saved under the label {specialLabel.name}.
            </>
          )}
        </div>
      </div>
      {/* {getImageContainer(format, img)} */}
      <div className="editor-controls">
        <button className="button--previous" type="button" onClick={onGoBack}>
          Previous
        </button>
        <button className="button--next" type="button" disabled={!selectedLabels.length} onClick={onContinue}>
          Next
        </button>
      </div>
    </div>
  );
};
