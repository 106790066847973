import {Layer, Stage} from 'react-konva';
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getOverlay} from '../../selectors/photoBooth';
import {actions} from '../../actions/photoBooth';
import {SelectionRectangle} from './SelectionRectangle';

const getRectDragBound = (maxWidth, maxHeight) =>
  function ({x, y}) {
    const maxX = maxWidth;
    const maxY = maxHeight;

    // `This` refers to the rectangle itself. scaleX and scaleY are the variables changing during transform
    const minX = (-this.width() * this.scaleX()) / 2;
    const minY = (-this.height() * this.scaleY()) / 2;

    x = Math.max(minX, Math.min(maxX - (this.width() * this.scaleX()) / 2, x));
    y = Math.max(minY, Math.min(maxY - (this.height() * this.scaleY()) / 2, y));

    return {
      x,
      y,
    };
  };

const getRectTransformBound = (maxX, maxY, minWidth = 24, minHeight = 24, ratio) => (oldRect, {width, height, x, y}) => {
  const maxWidth = ratio ? Math.min(maxX, maxY * ratio) : maxX;
  const maxHeight = ratio ? Math.min(maxY, maxX * ratio) : maxY;
  const resultRect = {x, y, width, height};

  if (width < minWidth || width > maxWidth || x < -width / 2 || x + width > maxX + width / 2) {
    resultRect.x = oldRect.x;
    resultRect.width = oldRect.width;
  }

  if (height < minHeight || height > maxHeight || y < -height / 2 || y + height > maxY + height / 2) {
    resultRect.y = oldRect.y;
    resultRect.height = oldRect.height;
  }

  return resultRect;
};

export const AreaSelectionControls = ({onSelectionChange, position, stageProps, minWidth, minHeight, transformerProps}) => {
  const selected = useSelector(getOverlay).selected;
  const dispatch = useDispatch();
  const setSelected = selected => dispatch(actions.Creators.setOverlaySelected(selected));

  const {width, height, transformRatio} = stageProps;

  return (
    <Stage
      className="area-selection-controls"
      {...stageProps}
      onTouchStart={e => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
          setSelected(false);
        }
      }}
    >
      <Layer>
        <SelectionRectangle
          transformBoundFn={getRectTransformBound(width, height, minWidth, minHeight, transformRatio)}
          dragBoundFn={getRectDragBound(width, height)}
          onTouchStart={() => setSelected(true)}
          position={position}
          isSelected={selected}
          onTransform={onSelectionChange}
          onDragMove={onSelectionChange}
          transformerProps={transformerProps}
        />
      </Layer>
    </Stage>
  );
};
