import {useState, useEffect} from 'react';

export const useAsyncState = (initialValue, initialSetter) => {
  const [value, setValue] = useState(initialValue);
  const [isLoaded, setIsLoaded] = useState(false);
  const setter = fn => {
    if (!fn) {
      return;
    }
    setIsLoaded(false);
    fn().then(resolve => {
      setValue(resolve);
      setIsLoaded(true);
    });
  };
  useEffect(() => {
    if (initialSetter) {
      setter(initialSetter);
    }
  }, [initialSetter]);
  return [value, setter, isLoaded];
};
