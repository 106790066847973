import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import {actions} from '../../actions/photoBooth';
import {getEditedGroupPhoto, getGroupCrop, getOverlay, getSpecialLabel} from '../../selectors/photoBooth';
import {IMG_PREVIEW_ASPECT_RATIO} from './constants';
import {AreaSelectionControls} from './AreaSelectionControls';
import {throttled} from './utils';

const getOverlayWrapperStyle = ({left, top, height, width}, image, aspectRatio = 1) => ({
  left: left * aspectRatio,
  top: top * aspectRatio,
  height: height * aspectRatio,
  width: width * aspectRatio,
});

const Editor = ({
  containerProps,
  dimensions,
  image,
  overlay,
  specialLabel,
  onSelectionChange,
  onOverlayLoaded,
  onImageLoaded = () => {},
  readOnly,
  aspectRatio,
  onContainerLoad,
  position,
}) => {
  const containerRef = useRef();
  const [imageDimensions, setImageDimensions] = useState(dimensions);

  const topOffset = 0;
  // Fixing the text-align center
  const leftOffset = containerRef.current ? Math.max(0, containerRef.current.clientWidth - imageDimensions.width) / 2 : 0;
  const isLandscape = imageDimensions.width > imageDimensions.height;
  // Fit the image in a square where the text takes 25% of the height and the image 75%
  const isLandscapePreview = imageDimensions.width * IMG_PREVIEW_ASPECT_RATIO > imageDimensions.height;

  return (
    <div className="editor-overlay-wrapper">
      <div
        className={`editor-overlay ${readOnly ? 'readOnly' : ''} ${isLandscape && 'is-landscape'} ${
          isLandscapePreview && 'is-landscape-preview'
        }`}
        ref={containerRef}
        {...containerProps}
      >
        <div onLoad={onContainerLoad} className="editor-overlay-images-container" style={{marginTop: topOffset}}>
          {!!image && (
            <img
              alt=""
              className="editor-overlay-photo"
              onLoad={e => {
                const {currentTarget} = e;
                // Fix for bugged safari width not calculated properly
                currentTarget.style.position = 'relative';
                setTimeout(() => {
                  currentTarget.style.position = '';
                }, 100);

                setImageDimensions(currentTarget);
                onImageLoaded(e);
              }}
              src={image}
            />
          )}
          {!!overlay.src && (
            <div className="editor-overlay-resizing-box" style={getOverlayWrapperStyle(overlay, image, aspectRatio)}>
              <img alt="" src={overlay.src} onLoad={onOverlayLoaded} />
            </div>
          )}
        </div>
        {!readOnly && (
          <AreaSelectionControls
            stageProps={{
              width: containerRef.current && containerRef.current.clientWidth,
              height: containerRef.current && containerRef.current.clientHeight,
              transformRatio: specialLabel.badgeWidth / specialLabel.badgeHeight,
              style: {
                position: 'absolute',
                bottom: 0,
              },
            }}
            minWidth={specialLabel.badgeWidth / 2}
            minHeight={specialLabel.badgeHeight / 2}
            // Default overlay position set only once
            position={{
              x: position.left * aspectRatio + leftOffset,
              y: position.top * aspectRatio + topOffset,
              width: position.width * aspectRatio,
              height: position.height * aspectRatio,
            }}
            transformerProps={{
              enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
              keepRatio: true,
            }}
            onSelectionChange={e => onSelectionChange(e, {top: topOffset / aspectRatio, left: leftOffset / aspectRatio}, aspectRatio)}
          />
        )}
      </div>
    </div>
  );
};

class OverlayEditorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.throttledOnSelectionChange = throttled(this.onSelectionChange, 25);

    this.state = {
      editorHeight: 0,
      editorWidth: 0,
      isLandscape: null,
      // Set the same position throughout the lifecycle of this component because the editor handles its transforms separately
      position: props.overlay,
    };
  }

  onUpdateHeight = () => {
    const height = (document && document.documentElement && document.documentElement.clientHeight) || window.innerHeight;
    const width = (document && document.documentElement && document.documentElement.clientWidth) || window.innerWidth;
    this.setState({isLandscape: height < width});
  };

  componentDidMount() {
    this.onUpdateHeight();
    window.addEventListener('resize', this.onUpdateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onUpdateHeight);
  }

  onContainerLoad = ({currentTarget}) => {
    this.setState({
      editorHeight: currentTarget.clientHeight,
      editorWidth: currentTarget.clientWidth,
    });
  };

  onSelectionChange = (e, offset = {}, aspectRatio = 1) => {
    const left = Math.round(e.currentTarget.x()) / aspectRatio - offset.left;
    const top = Math.round(e.currentTarget.y()) / aspectRatio - offset.top;

    // scaleX and scaleY are the variables changing during transform
    const width = Math.round(e.currentTarget.width() * e.currentTarget.scaleX()) / aspectRatio;
    const height = Math.round(e.currentTarget.height() * e.currentTarget.scaleY()) / aspectRatio;

    this.props.updateOverlay({left, top, width, height});
  };

  onOverlayLoaded = ({currentTarget}) => {
    this.props.updateOverlay({
      naturalWidth: currentTarget.naturalWidth,
      naturalHeight: currentTarget.naturalHeight,
      src: currentTarget.src,
    });
  };

  onImageLoaded = ({currentTarget}) => {
    this.props.updateImage({
      width: currentTarget.width,
      height: currentTarget.height,
      naturalHeight: currentTarget.naturalHeight,
      naturalWidth: currentTarget.naturalWidth,
    });
  };

  render() {
    const {overlay, image, dimensions, readOnly, specialLabel, containerProps} = this.props;

    const {editorHeight, editorWidth, position} = this.state;

    const editableProps = {
      onOverlayLoaded: this.onOverlayLoaded,
      onImageLoaded: this.props.updateImage,
      onSelectionChange: this.throttledOnSelectionChange,
    };

    const emptyFn = () => {};
    const readOnlyProps = {
      onOverlayLoaded: emptyFn,
      onImageLoaded: this.props.updateImage || emptyFn,
      onSelectionChange: emptyFn,
    };
    const editorProps = readOnly ? readOnlyProps : editableProps;

    const minRatio = Math.min(editorHeight / dimensions.height, editorWidth / dimensions.width);
    const aspectRatio = isNaN(minRatio) ? 1 : minRatio;

    if (this.state.isLandscape) {
      return (
        <div className="editor-overlay alert">
          <strong>Turn your phone in portrait mode to continue</strong>
        </div>
      );
    }

    return (
      <Editor
        aspectRatio={aspectRatio}
        overlay={overlay}
        image={image}
        dimensions={dimensions}
        readOnly={readOnly}
        onContainerLoad={this.onContainerLoad}
        containerProps={containerProps}
        position={position}
        specialLabel={specialLabel}
        {...editorProps}
      />
    );
  }
}

const mapStateToProps = state => ({
  overlay: getOverlay(state),
  dimensions: getGroupCrop(state),
  image: getEditedGroupPhoto(state),
  specialLabel: getSpecialLabel(state),
});

const mapDispatchToProps = dispatch => ({
  updateOverlay: overlay => dispatch(actions.Creators.updateOverlay(overlay)),
});

export const OverlayEditor = connect(mapStateToProps, mapDispatchToProps)(OverlayEditorContainer);
