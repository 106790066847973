import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router5';
import {openNativeFileDialog} from '@staffbase/plugins-client-sdk';

import {Resizer} from '../components/Resizer';
import {Error} from '../components/Error';
import {
  getLoading,
  getImage,
  getOpacity,
  getOverlay,
  getStep,
  getMessage,
  getUploadError,
  getImageUploading,
  getFormat,
  getEditedGroupPhoto,
  getFetchInProgress,
  getSelectedLabels,
  getLabels,
} from '../selectors/photoBooth';
import {getIsMobile, getIsAndroid} from '../selectors/platform';

import {actions} from '../actions/photoBooth';
import LoadingIndicator from '../components/LoadingIndicator';

import {ThankYou} from './picture-editor/ThankYou';
import {Intro} from './Intro';
import {PickFormat} from './PickFormat';
import {ApplyBadgeView} from './picture-editor/ApplyBadgeView';
import {MessageEditor} from './picture-editor/MessageEditor';
import {SPECIAL_FORMAT, NORMAL_FORMAT} from './picture-editor/constants';
import {Preview} from './picture-editor/Preview';
import {CropPhotoView} from './picture-editor/CropPhotoView';

class PhotoBoothContainer extends React.Component {
  openCamera = () => {
    this.cameraInput.click();
  };

  onContinue = () => {
    this.props.nextStep();
    window.scrollTo(0, 0);
  };

  onGoBack = () => {
    this.props.prevStep();
    window.scrollTo(0, 0);
  };

  onRestart = () => {
    this.props.restart();
  };

  onPictureLoaded = picture => {
    this.props.uploadImg(picture);
    this.onContinue();
  };

  onFormatPicked = async format => {
    const {isAndroid, setFormat} = this.props;
    setFormat(format);

    // DOM input doesn't work on Android in the plugin
    if (isAndroid) {
      openNativeFileDialog()
        .then(picture => {
          this.onPictureLoaded(picture);
        })
        .catch(e => console.error(`getting picture from android failed: ${e.message}`));
    } else {
      this.openCamera();
    }
  };

  onPictureTaken = ({target: input}) => {
    if (!input.files.length) return;

    this.onPictureLoaded(input.files[0]);
  };

  getCurrentStep = () => {
    const {
      img,
      opacity,
      overlay,
      setOpacity,
      pickOverlay,
      message,
      onMessageChanged,
      saveImage,
      imageUploading,
      step,
      format,
      editedGroupPhoto,
      fetchInProgress,
    } = this.props;

    const firstSteps = [
      <Intro key={'intro-view'} onContinue={this.onContinue} />,
      <React.Fragment key={'pick-format-view'}>
        <PickFormat onContinue={this.onFormatPicked} />
        <input
          style={{
            display: 'none',
          }}
          ref={e => (this.cameraInput = e)}
          type="file"
          accept="image/*"
          onChange={this.onPictureTaken}
          title="Camera"
        />
      </React.Fragment>,
    ];

    const lastSteps = [
      <Preview
        key={'preview-photo-view'}
        img={editedGroupPhoto}
        onGoBack={this.onGoBack}
        message={message}
        onSave={() => {
          saveImage();
          this.onContinue();
        }}
        onCancel={() => this.props.resetEditor()}
        format={this.props.format}
        fetchInProgress={fetchInProgress}
      />,
      <ThankYou key={'thank-you-view'} onRestart={this.onRestart} imageUploading={imageUploading} />,
    ];

    let middleSteps = [];
    switch (this.props.format) {
      case NORMAL_FORMAT:
        middleSteps = [
          <CropPhotoView img={img} onGoBack={() => this.props.resetEditor() && this.onGoBack()} onContinue={this.onContinue} />,
          <MessageEditor
            img={img}
            format={format}
            message={message}
            onMessageChanged={onMessageChanged}
            onGoBack={this.onGoBack}
            onContinue={this.onContinue}
            labels={this.props.labels}
            selectedLabels={this.props.selectedLabels}
            toggleLabel={this.props.toggleLabel}
          />,
        ];
        break;
      case SPECIAL_FORMAT:
        middleSteps = [
          <CropPhotoView
            key={'crop-photo-view'}
            img={img}
            onGoBack={() => this.props.resetEditor() && this.onGoBack()}
            onContinue={this.onContinue}
          />,
          <ApplyBadgeView
            key={'apply-badge-view'}
            img={editedGroupPhoto}
            opacity={opacity}
            onOpacityPicked={setOpacity}
            overlay={overlay}
            onOverlayPicked={pickOverlay}
            onGoBack={() => this.onGoBack()}
            onContinue={this.onContinue}
          />,
          <MessageEditor
            key={'message-editor-view'}
            format={format}
            img={editedGroupPhoto}
            message={message}
            onMessageChanged={onMessageChanged}
            onGoBack={this.onGoBack}
            onContinue={this.onContinue}
            labels={this.props.labels}
            selectedLabels={this.props.selectedLabels}
          />,
        ];
        break;
      default:
        middleSteps = [];
    }

    return firstSteps.concat(middleSteps).concat(lastSteps)[step];
  };

  render() {
    const {loading, uploadError, step, saveImage, imageUploading, isMobile} = this.props;

    if (process.env.NODE_ENV === 'production' && !isMobile && isMobile !== null && !loading) {
      return (
        <div className="editor">
          <div className="h-page-center" style={{maxWidth: 400, margin: '0 auto'}}>
            <p className="h-center h-mt-10 h-mb-20">
              The photo app is optimised for mobile devices and requires a camera. Please open the photo application within your r.e.source
              app on your phone.
            </p>
            <p className="h-center h-mt-10 h-mb-40">You can use the photo gallery to view all photos that have been taken so far.</p>
            <Link className="button button--primary h-mt-20" routeName="gallery">
              Open gallery
            </Link>
          </div>
        </div>
      );
    }

    if (uploadError) {
      return <Error onRetry={step > 2 && saveImage} imageUploading={imageUploading} onRestart={this.onRestart} />;
    }

    return <Resizer>{loading || isMobile === null ? <LoadingIndicator /> : this.getCurrentStep()}</Resizer>;
  }
}

const mapStateToProps = state => ({
  router: state.router,
  img: getImage(state).src,
  opacity: getOpacity(state),
  overlay: getOverlay(state).src,
  loading: getLoading(state),
  step: getStep(state),
  message: getMessage(state),
  imageUploading: getImageUploading(state),
  uploadError: getUploadError(state),
  format: getFormat(state),
  editedGroupPhoto: getEditedGroupPhoto(state),
  fetchInProgress: getFetchInProgress(state),
  labels: getLabels(state),
  selectedLabels: getSelectedLabels(state),
  isMobile: getIsMobile(state),
  isAndroid: getIsAndroid(state),
});

const mapDispatchToProps = dispatch => ({
  setOpacity: opacity => dispatch(actions.Creators.setOpacity(opacity)),
  pickOverlay: src => dispatch(actions.Creators.pickOverlay(src)),
  nextStep: () => dispatch(actions.Creators.nextStep()),
  prevStep: () => dispatch(actions.Creators.prevStep()),
  saveImage: () => dispatch(actions.Creators.saveImage()),
  onMessageChanged: msg => dispatch(actions.Creators.setMessage(msg)),
  setFormat: format => dispatch(actions.Creators.setFormat(format)),
  uploadImg: photo => dispatch(actions.Creators.uploadImg(photo)),
  resetEditor: () => dispatch(actions.Creators.resetEditor()),
  restart: () => dispatch(actions.Creators.restart()),
  toggleLabel: label => dispatch(actions.Creators.toggleLabel(label)),
});

export const PhotoBooth = connect(mapStateToProps, mapDispatchToProps)(PhotoBoothContainer);
