import React from 'react';
import {Link} from 'react-router5';

import ProgressIndicator from './ProgressIndicator';

const DEFAULT_ERROR = 'Please try again to upload your photo.';

export const Error = ({onRestart, onRetry, imageUploading, message = DEFAULT_ERROR}) => {
  return (
    <div className="editor">
      <div className="h-page-center">
        <h1 className="h-center">Sorry, something went wrong.</h1>
        <p className="h-center h-mt-10 h-mb-40">{message}</p>
        {imageUploading && <ProgressIndicator />}
        {onRetry && (
          <button type="button" className="button--primary h-mt-20" onClick={onRetry} disabled={imageUploading}>
            Retry
          </button>
        )}
        <button type="button" className="button--primary h-mt-20" onClick={onRestart}>
          Restart
        </button>
        <Link className="button button--secondary h-mt-20" routeName="gallery">
          Open gallery
        </Link>
      </div>
    </div>
  );
};
