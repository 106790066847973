import {createReducer} from 'reduxsauce';

import {actions} from '../actions/user';
import {actions as galleryActions} from '../actions/gallery';

const INITIAL_STATE = {
  imagesWithPendingLikeStatusChange: [],
  likedImageIds: new Set(),
};

const setImageWithPendingLikeStatusChange = (state, {imageId}) => {
  return {
    ...state,
    imagesWithPendingLikeStatusChange: [...state.imagesWithPendingLikeStatusChange, imageId],
  };
};

const addLikedImageId = (state, {imageId}) => {
  const newSet = new Set(state.likedImageIds);
  newSet.add(imageId);

  return {
    ...state,
    imagesWithPendingLikeStatusChange: state.imagesWithPendingLikeStatusChange.filter(id => id !== imageId),
    likedImageIds: newSet,
  };
};

const removeLikedImageId = (state, {imageId}) => {
  const newSet = new Set(state.likedImageIds);
  newSet.delete(imageId);

  return {
    ...state,
    imagesWithPendingLikeStatusChange: state.imagesWithPendingLikeStatusChange.filter(id => id !== imageId),
    likedImageIds: newSet,
  };
};

const setLikedImageIds = (state, {imageIds}) => ({...state, likedImageIds: new Set(imageIds)});

const updateLikedImageIds = (state, {images}) => {
  const updatedLikedImageIds = images.reduce((acc, updatedImage) => {
    if (updatedImage.userLiked !== 0) {
      acc.push(updatedImage.id);
    } else {
      acc = acc.filter(image => image !== updatedImage.id);
    }

    return acc;
  }, Array.from(state.likedImageIds));

  return {
    ...state,
    likedImageIds: new Set(updatedLikedImageIds),
  };
};

export const HANDLERS = {
  [actions.Types.SET_LIKED_IMAGE_IDS]: setLikedImageIds,
  [actions.Types.UPDATE_LIKED_IMAGE_IDS]: updateLikedImageIds,
  [actions.Types.ADD_LIKED_IMAGE_ID]: addLikedImageId,
  [actions.Types.REMOVE_LIKED_IMAGE_ID]: removeLikedImageId,
  [actions.Types.SET_IMAGE_WITH_PENDING_LIKE_STATUS_CHANGE]: setImageWithPendingLikeStatusChange,
  [galleryActions.Types.INCREMENT_IMAGE_LIKES]: addLikedImageId,
  [galleryActions.Types.DECREMENT_IMAGE_LIKES]: removeLikedImageId,
};

export const userReducer = createReducer(INITIAL_STATE, HANDLERS);
