import {takeEvery, call, select, put} from 'redux-saga/effects';

import {user} from '../actions';
import {getBearerToken, getUserId} from '../selectors/auth';
import {API} from './constants';

function* getLikedImageIds({resolve, reject}) {
  try {
    const token = yield select(getBearerToken);
    const userId = yield select(getUserId);

    const response = yield call(fetch, API.GET_USER_IMG_LIKES(userId), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      encoding: null,
    });

    const json = yield call([response, 'json']);
    const {docs} = json;
    if (response.ok) {
      yield put(user.Creators.setLikedImageIds(docs ? docs.map(({imageId}) => imageId) : []));
    }

    if (resolve) {
      resolve();
    }
  } catch (e) {
    console.error(e);
    if (reject) {
      reject(e);
    }
  }
}

export function* userSaga() {
  yield takeEvery(user.Types.GET_LIKED_IMAGE_IDS, getLikedImageIds);
}
