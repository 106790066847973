import React from 'react';

const Modal = React.forwardRef(({closeModal, children}, ref) => {
  return (
    <div className="modal" ref={ref}>
      <button type="button" className="button--close button--close-dark" onClick={closeModal} />
      <div className="modal-content">{children}</div>
    </div>
  );
});

export default Modal;
