import {createSelector} from 'reselect';

export const getPlatform = state => state.platform;

export const getIsNative = createSelector(getPlatform, platform => platform.isNative);

export const getIsIos = createSelector(getPlatform, platform => platform.isIos);

export const getIsAndroid = createSelector(getPlatform, platform => platform.isAndroid);

export const getIsMobile = createSelector(getPlatform, platform => platform.isMobile);
