import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../actions/photoBooth';
import {getFormat, getMessage, getStep} from '../../selectors/photoBooth';
import {NORMAL_FORMAT, SPECIAL_FORMAT} from './constants';

export const Navigation = ({onNavigate = () => {}}) => {
  const currentStep = useSelector(getStep);
  const message = useSelector(getMessage);
  const format = useSelector(getFormat);
  const dispatch = useDispatch();
  const setStep = step => dispatch(actions.Creators.setStep(step));

  let steps;
  switch (format) {
    case NORMAL_FORMAT:
      steps = [
        {
          onClick: setStep,
          className: 'editor-stepper-filter',
          label: 'Adjust Photo',
          step: 2,
        },
        {
          onClick: setStep,
          className: 'editor-stepper-text',
          label: 'Add Text',
          step: 3,
        },
        {
          onClick: step => message && setStep(step),
          className: 'editor-stepper-preview',
          label: 'Preview',
          step: 4,
        },
      ];
      break;
    case SPECIAL_FORMAT:
      steps = [
        {
          onClick: setStep,
          className: 'editor-stepper-filter',
          label: 'Adjust Photo',
          step: 2,
        },
        {
          onClick: setStep,
          className: 'editor-stepper-filter',
          label: 'Add Badge',
          step: 3,
        },
        {
          onClick: setStep,
          className: 'editor-stepper-text',
          label: 'Add Text',
          step: 4,
        },
        {
          onClick: step => message && setStep(step),
          className: 'editor-stepper-preview',
          label: 'Preview',
          step: 5,
        },
      ];
      break;
    default:
      break;
  }

  return (
    <ul className="editor-stepper">
      {steps.map(({step, label, onClick, className}) => (
        <li
          key={step}
          onClick={() => {
            onNavigate(step);
            onClick(step);
          }}
          className={`${className} ${currentStep === step ? 'active' : ''} ${currentStep > step ? 'completed' : ''}`}
        >
          <div />
          {label}
        </li>
      ))}
    </ul>
  );
};
