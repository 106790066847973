import {createActions} from 'reduxsauce';

export const actions = createActions(
  {
    getLikedImageIds: ['resolve', 'reject'],
    setLikedImageIds: ['imageIds'],
    updateLikedImageIds: ['images'],
    addLikedImageId: ['imageId'],
    removeLikedImageId: ['imageId'],
    setImageWithPendingLikeStatusChange: ['imageId'],
  },
  {prefix: 'user'},
);
