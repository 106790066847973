import {actions as routerActions, actionTypes as routerActionTypes} from 'redux-router5';
import {call, put, takeEvery} from 'redux-saga/effects';

import {storeAuthToken} from './auth';
import {actions as photoBoothActions} from '../actions/photoBooth';

function* onTransitionStart({payload: {route}}) {
  if (!route) {
    return;
  }
  try {
    yield call(storeAuthToken, route);
    if (route.name !== 'gallery') {
      yield put(photoBoothActions.Creators.restart());
    }
  } catch (e) {
    console.error(e);
    yield put(routerActions.cancelTransition());
  }
}

export function* routerSaga() {
  yield takeEvery(routerActionTypes.TRANSITION_START, onTransitionStart);
}
